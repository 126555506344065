export const CONNECT_WALLET_DATA = 'CONNECT_WALLET_DATA';
export const CONNECT_WALLET_ERROR = 'CONNECT_WALLET_ERROR';

export const DISCONNECT_WALLET_DATA = 'DISCONNECT_WALLET_DATA';
export const DISCONNECT_WALLET_ERROR = 'DISCONNECT_WALLET_ERROR';

export const GET_MY_BALANCE_DATA = 'GET_MY_BALANCE_DATA';
export const GET_MY_BALANCE_ERROR = 'GET_MY_BALANCE_ERROR';
export const GET_MY_BALANCE_LOADING_BUTTON = 'GET_MY_BALANCE_LOADING_BUTTON';

export const WALLET_ACCOUNT_DATA = 'WALLET_ACCOUNT_DATA';

export const WALLET_ACCOUNT_DETAIL_REQUEST = 'WALLET_ACCOUNT_DETAIL_REQUEST';
export const WALLET_ACCOUNT_DETAIL_DATA = 'WALLET_ACCOUNT_DETAIL_DATA';
export const WALLET_ACCOUNT_DETAIL_ERROR = 'WALLET_ACCOUNT_DETAIL_ERROR';
export const WALLET_ACCOUNT_DETAIL_MODAL = 'WALLET_ACCOUNT_DETAIL_MODAL';

export const WALLET_CONNECT_MODAL_REQUEST = 'WALLET_CONNECT_MODAL_REQUEST';
export const WALLET_CONNECT_MODAL_DATA = 'WALLET_CONNECT_MODAL_DATA';
export const WALLET_CONNECT_MODAL_ERROR = 'WALLET_CONNECT_MODAL_ERROR';
export const WALLET_CONNECT_MODAL = 'WALLET_CONNECT_MODAL';

export const WALLET_ACCOUNT_HISTORY_REQUEST = 'WALLET_ACCOUNT_HISTORY_REQUEST';
export const WALLET_ACCOUNT_HISTORY_DATA = 'WALLET_ACCOUNT_HISTORY_DATA';
export const WALLET_ACCOUNT_HISTORY_ERROR = 'WALLET_ACCOUNT_HISTORY_ERROR';
export const WALLET_ACCOUNT_HISTORY_MODAL = 'WALLET_ACCOUNT_HISTORY_MODAL';

export const STAKE_NOW_ACTIVATION_BUTTON = 'STAKE_NOW_ACTIVATION_BUTTON';


export const WALLET_INFO_DATA = 'WALLET_INFO_DATA';
export const WALLET_INFO_ERROR = 'WALLET_INFO_ERROR';


export const NETWORK_INFO_DATA = 'NETWORK_INFO_DATA';
export const NETWORK_INFO_ERROR = 'NETWORK_INFO_ERROR';
export const NETWORK_INFO_REQUEST = 'NETWORK_INFO_REQUEST';


