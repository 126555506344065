import * as types from './userActionTypes';

/**
 *
 * @param {username, password} creds
 */
export const loginActionRequest = (creds) => {
  return {
    type: types.LOGIN_REQUEST,
    creds,
  };
};
/**
 *
 * @param {username, token, two_factor_auth} user
 */
export const loginData = (user) => {
  return {
    type: types.LOGIN_DATA,
    user,
  };
};
export const loginError = (payload) => {
  return {
    type: types.LOGIN_ERROR,
    payload,
  };
};
/**
 *
 * @param {email} creds
 */
export const forgotPasswordRequest = (creds) => {
  return {
    type: types.FORGOT_PASSWORD_REQUEST,
    creds,
  };
};
export const forgotPasswordData = (payload) => {
  return {
    type: types.FORGOT_PASSWORD_DATA,
    payload,
  };
};
export const forgotPasswordError = (payload) => {
  return {
    type: types.FORGOT_PASSWORD_ERROR,
    payload,
  };
};
/**
 *
 * @param {password,confirmPassword,resetToken} creds
 */
export const resetPasswordRequest = (creds) => {
  return {
    type: types.RESET_PASSWORD_REQUEST,
    creds,
  };
};
export const resetPasswordData = (payload) => {
  return {
    type: types.RESET_PASSWORD_DATA,
    payload,
  };
};
export const resetPasswordError = (payload) => {
  return {
    type: types.RESET_PASSWORD_ERROR,
    payload,
  };
};
/**
 *
 * @param {activationToken,activationCode} creds
 */
export const activationRequest = (creds) => {
  return {
    type: types.ACTIVATION_REQUEST,
    creds,
  };
};
export const activationData = (payload) => {
  return {
    type: types.ACTIVATION_DATA,
    payload,
  };
};
export const activationError = (payload) => {
  return {
    type: types.ACTIVATION_ERROR,
    payload,
  };
};

export const logoutRequestAction = (payload) => {
  return {
    type: types.LOGOUT_REQUEST,
    payload,
  };
};
export const logoutData = (payload) => {
  return {
    type: types.LOGOUT_DATA,
    payload,
  };
};
export const logoutError = (payload) => {
  return {
    type: types.LOGOUT_ERROR,
    payload,
  };
};

/**
 *
 * @param {username, password, email} creds
 */
export const signUpRequest = (creds) => {
  return {
    type: types.SIGN_UP_REQUEST,
    creds,
  };
};
export const signUpData = (payload) => {
  return {
    type: types.SIGN_UP_DATA,
    payload,
  };
};
export const signUpError = (payload) => {
  return {
    type: types.SIGN_UP_ERROR,
    payload,
  };
};
export const accountDetailsRequestAction = (creds) => {
  return {
    type: types.ACCOUNT_DETAILS_REQUEST,
    creds,
  };
};
export const accountDetailsDataAction = (payload) => {
  return {
    type: types.ACCOUNT_DETAILS_DATA,
    payload,
  };
};
export const accountDetailsErrorAction = (payload) => {
  return {
    type: types.ACCOUNT_DETAILS_ERROR,
    payload,
  };
};
export const accountVerifiedAction = (payload) => {
  return {
    type: types.ACCOUNT_VERIFIED,
    payload,
  };
};

export const resendVerificationEmailRequestAction = (payload) => {
  return {
    type: types.RESEND_VERIFICATION_EMAIL_REQUEST,
    payload,
  };
};
export const resendVerificationEmailDataAction = (payload) => {
  return {
    type: types.RESEND_VERIFICATION_EMAIL_DATA,
    payload,
  };
};
export const resendVerificationEmailErrorAction = (payload) => {
  return {
    type: types.RESEND_VERIFICATION_EMAIL_ERROR,
    payload,
  };
};

//CheckUserToken
export const checkUserTokenRequestAction = (payload) => {
  return {
    type: types.CHECK_USER_TOKEN_REQUEST,
    payload,
  };
};
export const checkUserTokenDataAction = (payload) => {
  return {
    type: types.CHECK_USER_TOKEN_DATA,
    payload,
  };
};
export const checkUserTokenErrorAction = (payload) => {
  return {
    type: types.CHECK_USER_TOKEN_ERROR,
    payload,
  };
};


export const enable2faRequestAction = (payload) => {
  return {
    type: types.ENABLE_2FA_REQUEST,
    payload,
  };
};
export const enable2faDataAction = (payload) => {
  return {
    type: types.ENABLE_2FA_DATA,
    payload,
  };
};
export const enable2faErrorAction = (payload) => {
  return {
    type: types.ENABLE_2FA_ERROR,
    payload,
  };
};

export const generateQrRequestAction = (payload) => {
  return {
    type: types.GENERATE_QR_REQUEST,
    payload,
  };
};
export const generateQrDataAction = (payload) => {
  return {
    type: types.GENERATE_QR_DATA,
    payload,
  };
};
export const generateQrErrorAction = (payload) => {
  return {
    type: types.GENERATE_QR_ERROR,
    payload,
  };
};

export const verify2faRequestAction = (payload) => {
  return {
    type: types.VERIFY_2FA_REQUEST,
    payload,
  };
};
export const verify2faDataAction = (payload) => {
  return {
    type: types.VERIFY_2FA_DATA,
    payload,
  };
};
export const verify2faErrorAction = (payload) => {
  return {
    type: types.VERIFY_2FA_ERROR,
    payload,
  };
};

export const verify2faSuccessAction = (data) => ({
  type: 'VERIFY_2FA_SUCCESS',
  payload: data,
});

export const create2faRequestAction = (payload) => {
  return {
    type: types.CREATE_2FA_REQUEST,
    payload,
  };
};
export const create2faDataAction = (payload) => {
  return {
    type: types.CREATE_2FA_DATA,
    payload,
  };
};
export const create2faErrorAction = (payload) => {
  return {
    type: types.CREATE_2FA_ERROR,
    payload,
  };
};
/**
 *
 * @param {email} creds
 */
export const subscribeRequestAction = (creds) => {
  return {
    type: types.SUBSCRIBE_REQUEST,
    creds,
  };
};
export const subscribeDataAction = (payload) => {
  return {
    type: types.SUBSCRIBE_DATA,
    payload,
  };
};
export const subscribeErrorAction = (payload) => {
  return {
    type: types.SUBSCRIBE_ERROR,
    payload,
  };
};
/**
 *
 * @param {token} creds
 */
export const unsubscribeRequestAction = (creds) => {
  return {
    type: types.UNSUBSCRIBE_REQUEST,
    creds,
  };
};
export const unsubscribeDataAction = (payload) => {
  return {
    type: types.UNSUBSCRIBE_DATA,
    payload,
  };
};
export const unsubscribeErrorAction = (payload) => {
  return {
    type: types.UNSUBSCRIBE_ERROR,
    payload,
  };
};

export const getKycRequestAction = (creds) => {
  return {
    type: types.GET_KYC_REQUEST,
    creds,
  };
};
export const getKycDataAction = (payload) => {
  return {
    type: types.GET_KYC_DATA,
    payload,
  };
};
export const getKycErrorAction = (payload) => {
  return {
    type: types.GET_KYC_ERROR,
    payload,
  };
};


export const startKycRequestAction = (creds) => {
  return {
    type: types.START_KYC_REQUEST,
    creds,
  };
};
export const startKycDataAction = (payload) => {
  return {
    type: types.START_KYC_DATA,
    payload,
  };
};
export const startKycErrorAction = (payload) => {
  return {
    type: types.START_KYC_ERROR,
    payload,
  };
};
