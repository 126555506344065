/* eslint-disable max-len */
// import { Col } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import CheckBox from '../../assets/img/logo/Checkbox.png';
import './TripleCard.scss';

function TripleCard({ ...props }) {
  const { firstCard, secondCard, thirdCard, fourthCard, cardCount } = props;
  const cardCountSize = 12;
  return (
    <Row className="d-flex mt-5">
      {cardCount >= 1 && (
        <Col
          className="d-flex align-items-center text-center mb-4"
          md={12}
          lg={6}
          xl={cardCountSize / cardCount}
        >
          <Card.Body className="d-flex justify-content-center align-items-center triple-column">
            <div className="col-3">
              <img
                className="mb-5 m-auto checkbox-img"
                alt="CheckBox"
                src={CheckBox}
                // height={120}
                // width={100}
              />
            </div>
            <div className="col-9">
              <Card.Title className="d-flex justify-content-center text-fs-head-l mb-2">
                {firstCard.header}
              </Card.Title>
              <Card.Text className="d-flex justify-content-center text-fs-body-md">
                {firstCard.text}
              </Card.Text>
            </div>
          </Card.Body>
        </Col>
      )}
      {cardCount >= 2 && (
        <Col
          className="d-flex align-items-center text-center mb-4"
          md={12}
          lg={6}
          xl={cardCountSize / cardCount}
        >
          <Card.Body className="d-flex justify-content-center align-items-center triple-column">
            <div className="col-3">
              <img
                className="mb-5 m-auto checkbox-img"
                alt="CheckBox"
                src={CheckBox}
                height={120}
                width={100}
              />
            </div>
            <div className="col-9">
              <Card.Title className="d-flex justify-content-center text-fs-head-l mb-2">
                {secondCard.header}
              </Card.Title>
              <Card.Text className="d-flex justify-content-center text-fs-body-md">
                {secondCard.text}
              </Card.Text>
            </div>
          </Card.Body>
        </Col>
      )}
      {cardCount >= 3 && (
        <Col
          className="d-flex align-items-center text-center mb-4"
          md={12}
          lg={6}
          xl={cardCountSize / cardCount}
          
        >
          <Card.Body className="d-flex justify-content-center align-items-center triple-column">
            <div className="col-3">
              <img
                className="mb-5 m-auto checkbox-img"
                alt="CheckBox"
                src={CheckBox}
                height={120}
                width={100}
              />
            </div>
            <div className="col-9">
              <Card.Title className="d-flex justify-content-center text-fs-head-l mb-2">
                {thirdCard.header}
              </Card.Title>
              <Card.Text className="d-flex justify-content-center text-fs-body-md">
                {thirdCard.text}
              </Card.Text>
            </div>
          </Card.Body>
        </Col>
      )}
      {cardCount >= 4 && (
        <Col
          className="d-flex align-items-center text-center mb-4"
          md={12}
          lg={6}
          xl={cardCountSize / cardCount}
        >
          <Card.Body className="d-flex justify-content-center align-items-center triple-column">
            <div className="col-3">
              <img
                className="mb-5 m-auto checkbox-img"
                alt="CheckBox"
                src={CheckBox}
                height={120}
                width={100}
              />
            </div>
            <div className="col-9">
              <Card.Title className="d-flex justify-content-center text-fs-head-l mb-2">
                {fourthCard.header}
              </Card.Title>
              <Card.Text className="d-flex justify-content-center text-fs-body-md">
                {fourthCard.text}
              </Card.Text>
            </div>
          </Card.Body>
        </Col>
      )}
    </Row>
  );
}

export default TripleCard;
