export const stakingMultiplierInfo = [
  {
    id: 1,
    index:0,
    value:8000,
    title: 'TIER 1',
    requirement:'Stake minimum 8000 MERX',
    multiplier: 1.1,
    months: 1,
    features: [
      {
        content: 'Allocation: Guarateed',
        highlight: true,
      },
      {
        content: 'High allocation',
        highlight: false,
      },
      {
        content: 'Private sale access: Yes',
        highlight: false,
      },
      {
        content: 'Voting rights',
        highlight: false,
      },
      {
        content: 'Private sale pass',
        highlight: false,
      },
      
    ],
  },
  {
    id: 2,
    index:1,
    value:4000,
    title: 'TIER 2',
    requirement:'Stake minimum 4000 MERX',
    multiplier: 1.2,
    months: 3,
    features: [
      {
        content: 'Allocation: Guarateed',
        highlight: true,
      },
      {
        content: 'Medium allocation',
        highlight: false,
      },
      {
        content: 'Private sale access: Yes if private sale pass activated',
        highlight: false,
      },
    ],
  },
  {
    id: 3,
    index:2,
    value:1000,
    title: 'TIER 3',
    requirement:'Stake minimum 1000 MERX',
    multiplier: 1.3,
    months: 6,
    features: [
      {
        content: 'Allocation: First come first serve',
        highlight: true,
      },
      {
        content: 'Low allocation',
        highlight: false,
      },
      {
        content: 'Private sale access: Yes if private sale pass activated',
        highlight: false,
      },
    ],
  },
  // {
  //   id: 4,
  //   index:3,
  //   title: '12 MONTH',
  //   multiplier: 2,
  //   months: 12,
  //   features: [
  //     {
  //       content: '2x Multiplier on MERX locked.',
  //       highlight: true,
  //     },
  //     {
  //       content: 'Access to Special Airdrops.',
  //       highlight: false,
  //     },
  //     {
  //       content: 'Access to "Gold" IDOs.',
  //       highlight: false,
  //     },
  //   ],
  // },
];
