export const SET_LOADING = 'SET_LOADING';

export const GET_MY_BALANCE_LOADING = 'GET_MY_BALANCE_LOADING';
export const BUY_MERXTOKEN_LOADING = 'BUY_MERXTOKEN_LOADING';
export const SWAP_TOKEN_LOADING = 'SWAP_TOKEN_LOADING';
export const LOCK_TOKEN_LOADING = 'LOCK_TOKEN_LOADING';
export const CLAIM_TOKEN_LOADING = 'CLAIM_TOKEN_LOADING';

export const LOADING_FULL = "LOADING_FULL"

