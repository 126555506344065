export const lockingMultiplierInfo = [
  {
    id: 1,
    index:0,
    title: '1 MONTH',
    multiplier: 1.1,
    months: 1,
    features: [
      {
        content: '1.1x Multiplier on MERX locked.',
        highlight: true,
      },
      {
        content: 'Access to Special Airdrops.',
        highlight: false,
      },
      {
        content: 'Access to "Gold" IDOs.',
        highlight: false,
      },
    ],
  },
  {
    id: 2,
    index:1,
    title: '3 MONTH',
    multiplier: 1.2,
    months: 3,
    features: [
      {
        content: '1.2x Multiplier on MERX locked.',
        highlight: true,
      },
      {
        content: 'Access to Special Airdrops.',
        highlight: false,
      },
      {
        content: 'Access to "Gold" IDOs.',
        highlight: false,
      },
    ],
  },
  {
    id: 3,
    index:2,
    title: '6 MONTH',
    multiplier: 1.3,
    months: 6,
    features: [
      {
        content: '1.3x Multiplier on MERX locked.',
        highlight: true,
      },
      {
        content: 'Access to Special Airdrops.',
        highlight: false,
      },
      {
        content: 'Access to "Gold" IDOs.',
        highlight: false,
      },
    ],
  },
  {
    id: 4,
    index:3,
    title: '12 MONTH',
    multiplier: 2,
    months: 12,
    features: [
      {
        content: '2x Multiplier on MERX locked.',
        highlight: true,
      },
      {
        content: 'Access to Special Airdrops.',
        highlight: false,
      },
      {
        content: 'Access to "Gold" IDOs.',
        highlight: false,
      },
    ],
  },
];
