/* eslint-disable max-len */
import React from 'react';
import { Form, FormGroup } from 'react-bootstrap';
import './MercurXFormControl.scss';

const MercurXFormControl = (props) => {
  const { id, label, type, error, handleChange, value, placeholder } = props;
  const name = label.toLowerCase();

  return (
    <FormGroup>
      <Form.Label className="text-fs-body-lg text-t-head-color" for={name}>
        {label}
      </Form.Label>
      <Form.Control
        type={type}
        id={id ?? name}
        isInvalid={!!error}
        onChange={handleChange}
        value={value}
        placeholder={placeholder}
      />
      {/*  <FormFeedback>{error}</FormFeedback> */}
    </FormGroup>
  );
};

export default MercurXFormControl;
