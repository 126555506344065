import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './BannerLocking.scss';

function BannerLocking() {
  return (
    <Container className="launchpad-banner text-white">
      <Row className="text-fs-head-xs">
        <Col>Home/Locking</Col>
      </Row>
      <Row className="text-fs-title-md mt-4">
        <Col lg={4} md={6}>
        LOCK & EARN MERX
        </Col>
      </Row>
      {<Row className="text-fs-body-md mt-4">
        <Col lg={4} md={6}>
          We have all been in this industry too long not to make the security of your funds our
          absolute top priority.
        </Col>
      </Row>}
    </Container>
  );
}

export default BannerLocking;
