import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom'; 
import './BannerHome.scss';


function BannerHome() {
  const history = useHistory();

  const handleLearnMoreClick = () => {
    history.push('/launchpad'); 
  };

  return (
    <Container className="home-banner">
      <Row className="text-fs-title-md text-white">
        <Col>
          <div>Launching</div>
          <div>Web3 Projects With</div>
          <div>Security and Simplicity</div>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <Button variant="primary" style={{ width: '212px' }} onClick={handleLearnMoreClick}>
            Learn More
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default BannerHome;
