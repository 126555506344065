import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/style.scss';
import App from './App';
import { Provider } from 'react-redux';
import store from './store';
import MercurXAlerts from './components/MercurXAlerts/MercurXAlerts';
import MercurXLoadingFull from './components/MercurXLoadingFull/MercurXLoadingFull';

const app = (
  <Provider store={store}>
    <MercurXAlerts />
    <MercurXLoadingFull/>
    <App />
  </Provider>
);

// ReactDOM.render(app, document.getElementById('root'));
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(app);
