export const tierInfo = [
  {
    id: 1,
    index:0,
    value:8000,
    title: 'TIER 1',
    requirement:'Stake minimum 8000 MERX',
    multiplier: 1.1,
    months: 1,
    features: [
      {
        content: 'Allocation: Guarateed',
        highlight: true,
      },
      {
        content: 'High allocation',
        highlight: false,
      },
      {
        content: 'Private sale access: Yes',
        highlight: false,
      },
      {
        content: 'Voting rights',
        highlight: false,
      },
      {
        content: 'Private sale pass',
        highlight: false,
      },
    ],
  },
  {
    id: 2,
    index:1,
    value:4000,
    title: 'TIER 2',
    requirement:'Stake minimum 4000 MERX',
    multiplier: 1.2,
    months: 3,
    features: [
      {
        content: 'Allocation: Guarateed',
        highlight: true,
      },
      {
        content: 'Medium allocation',
        highlight: false,
      },
      {
        content: 'Private sale access: Yes if private sale pass activated',
        highlight: false,
      },
    ],
  },
  {
    id: 3,
    index:2,
    value:1000,
    title: 'TIER 3',
    requirement:'Stake minimum 1000 MERX',
    multiplier: 1.3,
    months: 6,
    features: [
      {
        content: 'Allocation: First come first serve',
        highlight: true,
      },
      {
        content: 'Low allocation',
        highlight: false,
      },
      {
        content: 'Private sale access: Yes if private sale pass activated',
        highlight: false,
      },
    ],
  },
];

// export const tierInfo = [
//   {
//     id: 1,
//     title: 'TIER 0',
//     multiplier: 1.1,
//     months: 1,
//     features: [
//       {
//         content: '< 50 MERX',
//         highlight: true,
//       },
//       {
//         content: 'Regular User.',
//         highlight: false,
//       },
//       {
//         content: 'Unable to Access Special Events and Airdrops.',
//         highlight: false,
//       },
//     ],
//   },
//   {
//     id: 2,
//     title: 'TIER 1',
//     multiplier: 1.3,
//     months: 3,
//     features: [
//       {
//         content: 'Between 50 - 1000 MERX',
//         highlight: true,
//       },
//       {
//         content: 'Bronze User',
//         highlight: false,
//       },
//       {
//         content: 'First Come, First Buy',
//         highlight: false,
//       },
//     ],
//   },
//   {
//     id: 3,
//     title: 'TIER 2',
//     multiplier: 1.5,
//     months: 6,
//     features: [
//       {
//         content: 'Between 1000 - 4000 MERX',
//         highlight: true,
//       },
//       {
//         content: 'Silver User.',
//         highlight: false,
//       },
//       {
//         content: 'Early Access to Special Events',
//         highlight: false,
//       },
//     ],
//   },
//   {
//     id: 4,
//     title: 'TIER 3',
//     multiplier: 2,
//     months: 12,
//     features: [
//       {
//         content: '> 4000 MERX',
//         highlight: true,
//       },
//       {
//         content: 'Gold User.',
//         highlight: false,
//       },
//       {
//         content: 'Guarantee buying option for Special Event',
//         highlight: false,
//       },
//     ],
//   },
// ];

