import React from 'react';
import './MercurXProjectInfo.scss';

function ProjectYourAllocations({ ...props }) {
  const { className, project, history } = props;

  return (
    <div>
      
    </div>
  );
}

export default ProjectYourAllocations;
