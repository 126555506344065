const LOCKING_HISTORY_SORT_TYPES = {
  ASCENDING: 0,
  DESCENDING: 1,
};

const LOCKING_HISTORY_KEYS = {
  NAME: 0,
  VALUE: 1,
  MONTH: 2,
};

const sortTypes = [
  {
    name: 'Ascending',
    icon: 'BiSortUp',
  },
  {
    name: 'Descending',
    icon: 'BiSortDown',
  },
];

const sortKeys = [
  { name: 'Name', key: 'name' },
  { name: 'Value', key: 'value' },
  { name: 'Month', key: 'month' },
];

export { LOCKING_HISTORY_SORT_TYPES, LOCKING_HISTORY_KEYS, sortTypes, sortKeys };
