import React from 'react';
import './MercurXProjectInfo.scss';

function ProjectMediaReleases({ ...props }) {
  const { project, history } = props;

  return (
    <div>
 
    </div>
  );
}

export default ProjectMediaReleases;