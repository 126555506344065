import React, { useState } from 'react';
import { Row, Col, Button, InputGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import './Subscribe.scss';
import { subscribeRequestAction } from '../../store/account/userActions';

function Subscribe({ ...props  }) {
  const { subscribe } = props;
  const [state, setState] = useState({
    email: '',
    status:true,
    errors: {},
  });

  const validate = () => {
    const { email } = state;
    const errors = {};

    if (!email) {
      errors.email = 'Email cannot be blank.';
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        errors.email = 'Invalid email format.';
      }
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validate();

    if (Object.keys(errors).length === 0) {

      subscribe(state);

      setState({
        email: '',
        status:true,
        errors: {},
      });
    } else {
      setState({
        ...state,
        errors,
      });
    }
  };

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.id]: e.target.value,
      errors: {
        ...state.errors,
        [e.target.id]: '',
      },
    });
  };

  const { email, errors } = state;

  return (
    <>
      <div className="subscribe mt-4">
        <Row>
          <Col className="Subscribe-title">Subscribe For Newsletter</Col>
        </Row>
        <Row>
          <Col></Col>
          <Col lg={8} md={8} className="Subscribe-text">
            Embark on the adventure of a lifetime - sign up now and sail through the thrilling
            waters of the MercurX Universe, where every newsletter is a treasure chest waiting to be
            unlocked!
          </Col>
          <Col></Col>
        </Row>

        <Row className="email-row" style={{ width: '70%' }}>
          <Col>
            <InputGroup className="email-form">
              <input
                id="email"
                className="email form-control"
                type="email"
                placeholder="Email Address"
                onChange={handleChange}
                value={email}
                aria-describedby="emailError"
                aria-invalid={errors.email ? "true" : "false"}
              />
              <Button className="email-button" variant="primary" onClick={handleSubmit}>
                Subscribe
              </Button>
            </InputGroup>
            <div id="emailError" className="text-danger text-fs-body-sm mercurx-error-label">
              {errors.email && '*' + errors.email}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    subscribe: (creds) => {
      dispatch(subscribeRequestAction(creds));
    },
  };
};

export default connect(null, mapDispatchToProps)(Subscribe);
