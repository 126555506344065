const STAKING_HISTORY_SORT_TYPES = {
  ASCENDING: 0,
  DESCENDING: 1,
};

const STAKING_HISTORY_KEYS = {
  DATE: 0,
  VALUE: 1,
  NAME: 2,
  
};

const sortTypes = [
  {
    name: 'Ascending',
    icon: 'BiSortUp',
  },
  {
    name: 'Descending',
    icon: 'BiSortDown',
  },
];

const sortKeys = [
  { name: 'Date', key: 'date' },
  { name: 'Value', key: 'value' },
  { name: 'Name', key: 'name' },
];

export { STAKING_HISTORY_SORT_TYPES, STAKING_HISTORY_KEYS, sortTypes, sortKeys };
