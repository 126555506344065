import React from 'react';
import Subscribe from '../../components/MercurXSubscribe/Subscribe';
//import HomeSales from '../../components/MercurXHomeSales/HomeSales';
import './About.scss';
import { Container } from 'react-bootstrap';
import MercurXAboutUs from '../../components/MercurXAboutUs/MercurXAboutUs';

function About({ ...props }) {
  return (
    <Container>
      <div className="border-0 p-4 shadow">
        <div className="text-fs-head-sm text-center">MERCURX</div>
        <p className="text-fs-body-md">
          MercurX is a decentralized platform for different size of investors who wants to invest
          blockchain based crypto currencies. Platform members can generate new investment
          instruments and provide financing in an easy, flexible and transparent environment.
          Cryptocurrency investors generally decide what and how much to invest by focusing on 3 big
          problems. These 3 problems are transparency, sustainability and high return.
        </p>
        <p className="text-fs-body-md">
          Crypto-assets issued to date have generally met with investors behind closed doors, with
          the very high portion of the return occurring in the segment from those behind-the-scenes
          talks to the Initial Public Offering. Small investors, on the other hand, were generally
          not aware of these investments at this first stage.Those who knew were having difficulty
          in meeting the prerequisites to participate in these investments. With the development of
          platforms with transparent and shared decision mechanisms, small investors started to find
          a place in the market and earn money from their investments in a fair way. After that, The
          sustainability became the next problem. Now, both transparent and sustainable platforms
          have emerged as a need.
        </p>
        <p className="text-fs-body-md">
          MercurX is committed to providing a decentralized, transparent, reliable and sustainable
          environment for the solution of the 3 main problems mentioned above. In this context, we
          offer investors a platform where investment records are kept safely, together with the
          reliable structure of the blockchain. Investors can transparently negotiate more favorable
          terms and maximize the return on their investment on this platform. Using this platform,
          MercurX customers can create crypto assets and bring them together with investors. All
          these steps take place in a completely safe and transparent manner. The historical records
          of these transactions are also securely stored with the blockchain method. Unlike other
          platforms, MercurX will also focus important sectors besides De-Fi, Gaming and Metaverse
          space by supporting, offering and prioritization of those projects. Example of the sectors
          are Energy, Tourism, Manufacturing and Retail. Not only we will offer projects in these
          sectors, MercurX will also create an awareness about these projects and support the use
          cases of the projects in both enterprise and crypto platforms.
        </p>
        <p className="text-fs-body-md">
          MercurX has its own token decentralized economy. The MercurX Token(MERX) is central token
          that facilitate the economic model of MercurX, as it serves
          <div>
            * How tokens will be circulated (e.g., token distribution, allocations,
            re-distributions, etc.)
          </div>
          <div>
            * The rights, incentives, mechanisms, governance, access and utilities are formulated
          </div>
        </p>
        <p className="text-fs-body-md">
          MercurX token economics is a self-organized, early-supporter and long-term incentivized
          model where we deploy various game theory and mechanism design approaches to secure
          long-lasting sustainability. As MercurX aims to provide a trusted bridge between business
          incentives and consumers; MercurX Token will enable all shareholders through their journey
          on MercurX. For both project owners and consumers; to be able to participate in listed
          IDO/ICO projects for a fairly and delicately defined allocation mechanism, MercurX Token
          will be used and utilized in this process.
        </p>
        <p className="text-fs-body-md">
          Consumers (Token holders, Traders, etc.)
          <div>Guaranteed allocation in MercurX Launchpad - Lock Mechanism at beginning</div>
          <div>* Can buy tokens on the both centralized and decentralized exchange </div>
          <div>* Can buy tokens from the platform directly </div>
          <div>* Can pay service fees with token </div>
          <div>* Can provide liquidity and earn rewards</div>
          <div>* Can earn increased referral rewards when holding tokens</div>
        </p>
        <p className="text-fs-body-md">
          Stakers - 2nd Phase(TBD) <div>* Earn staking rewards</div>
        </p>
        <p className="text-fs-body-md">
          Platform
          <div>* Can earn service fees </div>
          <div>* Can earn tokens from transaction fees</div>
          <div>* Can sell tokens to citizens </div>
          <div>* Can burn tokens to decrease supply and increase value</div>
        </p>
      </div>

      <MercurXAboutUs />
      <Subscribe />
    </Container>
  );
}

export default About;
