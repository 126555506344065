/* eslint-disable max-len */
import { put, all, call, takeEvery } from 'redux-saga/effects';
import * as types from './walletActionTypes';
import * as actions from './walletActions';
// import * as alert from '../alert/alertActions';
import * as endpoints from '../../services/endpoints';


function* walletAccountHistory(action) {
  try {
    const { data } = yield call(endpoints.getWalletHistory, action.payload.id);
    yield put(actions.walletAccountHistoryDataAction(data));
    yield put(actions.walletAccountHistoryModalAction(true));

  } catch (e) {
    // yield put(
    //   alert.setAlertAction({
    //     title: 'Error!',
    //     text: 'Something went wrong when calling tier service.',
    //     color: 'danger',
    //     outTimeMS: 6000,
    //   }),
    // );
    yield put(actions.walletAccountHistoryErrorAction(e));
  }
}
function* newtorkInfoSaga({payload}) {
  try {
    
    const { data } = yield call(endpoints.newtorkChain,payload.chainId);
    yield put (actions.networkInfoDataAction(data))
  } catch (e) {
    yield put(actions.networkInfoErrorAction(e));
  }
}

function* watchWalletAccountHistory() {
  yield takeEvery(types.WALLET_ACCOUNT_HISTORY_REQUEST, walletAccountHistory);
}
function* watchNetworkInfo() {
  yield takeEvery(types.NETWORK_INFO_REQUEST, newtorkInfoSaga);
}

export function* walletSaga() {
  yield all([watchWalletAccountHistory(),watchNetworkInfo()]);
}
