import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './BannerAbout.scss';

function BannerAbout() {
  return (
    <>
      <Container className='about-us-banner'>
        <Row className="text-white text-fs-head-xs">
          <Col>Home / About Us</Col>
        </Row>
        <Row className="text-white text-fs-title-md mt-4">
          <Col lg={12} md={12}>
            About Us
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default BannerAbout;
