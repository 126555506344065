import {
  STAKING_HISTORY_KEYS,
  STAKING_HISTORY_SORT_TYPES,
  sortKeys,
} from '../../pages/Staking/stakingConstants';
import * as types from './stakingActionTypes';

const initialState = {
  //dummy staking history data
  stakingHistoryData: null,
  stakingStatisticsData: null,
  stakingHistorySortData: {
    sortType: STAKING_HISTORY_SORT_TYPES.ASCENDING,
    sortKey: STAKING_HISTORY_KEYS.DATE,
  },
  postedStake: null,
  error: {
    type: null,
    data: null,
  },
};

export const stakingReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_STAKING_HISTORY_DATA:
      return {
        ...state,
        // stakingHistoryData: action?.payload ? [...action?.payload] : null,
        stakingHistoryData: action?.payload?.staked_tokens || null,
      };
    case types.GET_STAKING_HISTORY_ERROR:
      return {
        ...state,
        error: {
          type: types.GET_STAKING_HISTORY_ERROR,
          data: action.payload,
        },
      };
    case types.GET_STAKING_STATISTICS_DATA:
      return {
        ...state,
        stakingStatisticsData: action?.payload ? { ...action?.payload } : null,
      };
    case types.GET_STAKING_STATISTICS_ERROR:
      return {
        ...state,
        error: {
          type: types.GET_STAKING_STATISTICS_ERROR,
          data: action.payload,
        },
      };
    case types.POST_STAKING_DATA:
      return {
        ...state,
        postedStake: action?.paylaod ? { ...action?.payload } : null,
      };
    case types.POST_STAKING_ERROR:
      return {
        ...state,
        error: {
          type: types.POST_STAKING_ERROR,
          data: action.payload,
        },
      };

    case types.PUT_STAKING_DATA:
      return {
        ...state,
        putStake: action?.paylaod ? { ...action?.payload } : null,
      };
    case types.PUT_STAKING_ERROR:
      return {
        ...state,
        error: {
          type: types.PUT_STAKING_ERROR,
          data: action.payload,
        },
      };
    case types.SET_STAKING_HISTORY_SORT_DATA:
      return {
        ...state,
        stakingHistorySortData: { ...action.payload },
      };
    // case types.SORTING_STAKING_HISTORY:
    //   const selectedKey = sortKeys[state.stakingHistorySortData.sortKey].key;
    //   const stakingHistoryData = state.stakingHistoryData;
    //   const sortedStakingHistoryData = stakingHistoryData?.sort((a, b) => {
    //     if (state.stakingHistorySortData.sortType === STAKING_HISTORY_SORT_TYPES.ASCENDING) {
    //       return a[selectedKey]
    //         ?.toString()
    //         .toLowerCase()
    //         .localeCompare(b[selectedKey]?.toString().toLowerCase());
    //     } else {
    //       return b[selectedKey]
    //         ?.toString()
    //         .toLowerCase()
    //         .localeCompare(a[selectedKey]?.toString().toLowerCase());
    //     }
    //   });
    case types.SORTING_STAKING_HISTORY:
      const selectedKey = sortKeys[state.stakingHistorySortData.sortKey].key;
      const stakingHistoryData = state.stakingHistoryData;
      const sortedStakingHistoryData = stakingHistoryData?.sort((a, b) => {
        // Parse dates for comparison
        const dateA = new Date(a.udate);
        const dateB = new Date(b.udate);
    
        if (state.stakingHistorySortData.sortType === STAKING_HISTORY_SORT_TYPES.DESCENDING) {
          return dateB - dateA; 
        } else {
          return dateA - dateB; 
        }
      });
      
    //default olarak date ascending olsun.
      return {
        ...state,
        stakingHistoryData: sortedStakingHistoryData ? [...sortedStakingHistoryData] : null,
      };
    default:
      return state;
  }
};
