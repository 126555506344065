import { useEffect } from 'react';
import { connect } from 'react-redux';
import MercurXFooter from '../../components/MercurXFooter/MercurXFooter';
import MercurXNavbar from '../../components/MercurXNavbar/MercurXNavbar';
import './PublicLayout.scss';

function PublicLayout({ ...props }) {
  const { children } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <>
      <MercurXNavbar {...props} isPublic={true} />
      <div className="public-layout d-flex flex-row justify-content-center py-5 my-5">
        {children}
      </div>
      <MercurXFooter {...props} />
    </>
  );
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(PublicLayout);
