import ocConsutingImgSrc from '../assets/img/quick-handler-images/offer-card-consulting.png';
// eslint-disable-next-line max-len
import ocProjectDeliveryImgSrc from '../assets/img/quick-handler-images/offer-card-project-delivery.png';
// eslint-disable-next-line max-len
import ocLaunchYourTokenImgSrc from '../assets/img/quick-handler-images/offer-card-launch-your-token.png';


export const offerCardImages = {
  ocConsultingImg: {
    src: ocConsutingImgSrc,
    width: 128,
    height: 150,
  },
  ocProjectDeliveryImg: {
    src: ocProjectDeliveryImgSrc,
    width: 137,
    height: 150,
  },
  ocLaunchYourTokenImg: {
    src: ocLaunchYourTokenImgSrc,
    width: 236,
    height: 150,
  },
};
