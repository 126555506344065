import { doGet, doPost, doPut } from './axiosCrud';

//User
export const login = (body) => doPost('/login', body);
export const signUp = (body) => doPost('/signup', body);
export const forgotPassword = (body) => doPost('/password-recovery', body);
export const subscribe = (body) => doPost('/subscribe', body);
export const unsubscribe = (body) => doPost('/unsubscribe', body);
export const resetPassword = (body) => doPost('/reset-password', body);
export const activation = (body) => doPost('/activate', body);
export const logut = (body) => doPost('/login', body);
export const kycStart = () => doPost('/user/kyc')
export const kycApiKey = () => doGet('/user/kyc')
export const getAccountDetails = (body) => doGet('/user/profile', body);
export const resendVerificationEmail = () => doPost('/user/activation-email');
export const checkUserToken = () => doGet('/user/check_token');
export const transaction = (body) => doPost('/transaction', body);
export const newtorkChain = (chainId) => doPost('/chain/' + chainId);
export const registrationToken = (body) => doPost('/register_project', body);

//Post
export const getPosts = () => doGet('/projects');
export const getPostDetails = (id) =>
  doGet('', {
    params: {
      id: id,
    },
  });

//Projects
export const getProjects = () => doGet('/projects');
export const getProjectByID = (id) => doGet('/projects/' + id);
export const addProject = (body) => doPost('/projects', body);

//Tokens
export const getTokens = () => doGet('/tokens');
export const getTokenByID = (id) => doGet('/tokens/' + id);
export const addToken = (body) => doPost('/tokens', body);

//bscApi
export const GetTxlist = (body) => doPost('/bsc', body);
export const getWalletHistory = (wallet) => doGet('/user/wallet_history/' + wallet);

//bscApi
export const GetAbiList = (body) => doGet('/contracts/abi', body);
export const getBNB = () => doGet('/bnb');

//tier
export const getTier = (id) => doGet('/tier/' + id);
export const putTier = (id) => doPut('/tier/' + id);

//locking
export const getLockingHistory = (id) => doGet('/lock/' + id);
export const postLocking = (body) => doPost('/lock', body);
export const getLockingStatistics = (wallet) => doGet('/locking/history/' + wallet);
export const putLocking = (id) => doPut('/lock/' + id);

//staking
export const getStakingHistory = (id) => doGet('/stake/' + id);
export const postStaking = (body) => doPost('/stake', body);
export const getStakingStatistics = (wallet) => doGet('/staking/history/' + wallet);
export const putStaking = (id) => doPut('/stake/' + id);
// //default
export const enable2fa = (totp_code) => doPost('/enable_2fa/' + `${totp_code}`);
export const getGenerateQR = (body) => doGet('/generate_qr');
export const verify2fa = (totp_code) => doPost('/verify_2fa/'
 + `${totp_code}`);
 export const create2fa = () => doPost('/create_2fa');