import { Col, Container, Nav, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// import beIcon from '../../../assets/img/logo/behance.svg';
// import linkedinIcon from '../../../assets/img/logo/linkedin.svg';
// import twitterIcon from '../../../assets/img/logo/twitter.svg';
import MercurXIcons from '../../MercurXIcons/MercurXIcons';

import './BannerToken.scss';
import { connect } from 'react-redux';

import MercurXSpinner from '../../MercurXSpinner/MercurXSpinner';

function BannerToken({ ...props }) {
  const { project } = props;

  if (project) {
    return (
      <>
        <Container className="token-banner">
          <Row className="text-fs-head-xs text-white">
            <Col>Home/Sales/Product Page</Col>
          </Row>
          <Row className="text-fs-title-md mt-4 text-white">
            <Col lg={12} md={12}>
              {project.name}
            </Col>
          </Row>
          <Row className="text-fs-body-md mt-4 text-white">
            <Col lg={4} md={6}>
              {project.explanation_text}
            </Col>
          </Row>
          {project.social ? (
            <Row className="text-fs-body-md mt-4">
              <Col className="token-banner-icon">
                <ul>
                  {project.social.telegram ? (
                    <li>
                      <Nav.Link
                        as={Link}
                        to={{ pathname: project.social.telegram }}
                        target="_blank"
                      >
                        <MercurXIcons name="FaTelegram" color={'white'} size="46" />
                      </Nav.Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {project.social.discord ? (
                    <li>
                      <Nav.Link as={Link} to={{ pathname: project.social.discord }} target="_blank">
                        <MercurXIcons name="FaDiscord" color={'white'} size="46" />
                      </Nav.Link>
                    </li>
                  ) : (
                    <></>
                  )}
                  {project.social.twitter ? (
                    <li>
                      <Nav.Link as={Link} to={{ pathname: project.social.twitter }} target="_blank">
                        <MercurXIcons name="FaTwitter" color={'white'} size="46" />
                      </Nav.Link>
                    </li>
                  ) : (
                    <></>
                  )}
                </ul>
              </Col>
            </Row>
          ) : (
            <></>
          )}
        </Container>
      </>
    );
  } else {
    return <MercurXSpinner />;
  }
}
const mapStateToProps = (state) => {
  return {
    project: state.projectReducer.project,
  };
};

export default connect(mapStateToProps)(BannerToken);
