import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './BannerTerms.scss';

function BannerTerms() {
  return (
    <>
      <Container className='about-us-banner'>
        <Row className="text-white text-fs-head-xs">
          <Col>Home / Terms</Col>
        </Row>
        <Row className="text-white text-fs-title-md mt-4">
          <Col lg={12} md={12}>
            Terms
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default BannerTerms;
