import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import './MercurXQuickHandler.scss';
import { checkUserWalletAccount } from '../../helpers/verificationHelper';
import wallet from '../../helpers/wallet';

function MercurXQuickHandler({ ...props }) {
  const {
    user,
    accounts,
    isSignUpAndKYC,
    signUpAndKYCImg,
    isVerifyWallet,
    verifyWalletImg,
    isStakeMerxToken,
    stakeMerxTokenImg,
    registerForSaleImg,
    history,
    className,
  } = props;

  const [signUpAndKycRef, setSignUpAndKycRef] = useState(null);
  const [verifyWalletRef, setVerifyWalletRef] = useState(null);
  const [stakeMerxTokenRef, setStakeMerxTokenRef] = useState(null);
  const [registerForSaleRef, setRegisterForSaleRef] = useState(null);

  const [size, setSize] = useState([0, 0]);

  function updateSize() {
    setSize([window.innerWidth, window.innerHeight]);
  }

  useEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, []);

  function getImgStyle(ref, img) {
    const halfHeight = Math.floor(img?.height / 2);
    return {
      position: 'absolute',
      top: ref?.offsetTop - halfHeight,
    };
  }

  return (
    <>
      <Row className={className}>
        {isSignUpAndKYC && (
          <Col
            id="sign-up-and-kyc-col"
            ref={(newRef) => setSignUpAndKycRef(newRef)}
            className="quick-handler-col"
            lg={isStakeMerxToken ? '3' : '4'}
            md="6"
            sm="12"
          >
            <div className="d-flex justify-content-center align-items-start">
              <img
                alt="sign-up-and-kyc"
                src={signUpAndKYCImg.src}
                style={getImgStyle(signUpAndKycRef, signUpAndKYCImg)}
                width={signUpAndKYCImg.width}
                height={signUpAndKYCImg.height}
              />
            </div>

            <div
              className="bg-white rounded pb-5 px-4 mx-1 shadow 
              quick-handler-container d-flex flex-column justify-content-end"
            >
              <div className="h6 mb-0 text-center text-primary text-fs-head-xs">
                SIGN UP AND KYC
              </div>
              <div
                className="py-3 text-t-body-color text-center text-fs-body-md 
              quick-handler-body"
              >
                To get involved in MercurX sales, the first step is to complete the signup and KYC
                process.
              </div>
              <div className="d-flex justify-content-center border-top-0">
                <Button
                  className="text-fs-head-sm py-2"
                  variant="primary"
                  disabled={user}
                  onClick={() => {
                    if (!user) {
                      history.push('/signup');
                    }
                  }}
                >
                  {user ? 'Signed' : 'Sign Up'}
                </Button>
              </div>
            </div>
          </Col>
        )}
        {isVerifyWallet && (
          <Col
            id="verify-wallet-col"
            ref={(newRef) => setVerifyWalletRef(newRef)}
            className="quick-handler-col"
            lg={isStakeMerxToken ? '3' : '4'}
            md="6"
            sm="12"
          >
            <div className="d-flex justify-content-center align-items-start">
              <img
                alt="sign-up-and-kyc"
                src={verifyWalletImg.src}
                style={getImgStyle(verifyWalletRef, verifyWalletImg)}
                width={verifyWalletImg.width}
                height={verifyWalletImg.height}
              />
            </div>

            <div
              className="bg-white rounded pb-5 px-4 mx-1 shadow 
              quick-handler-container d-flex flex-column justify-content-end"
            >
              <div className="h6 mb-0 text-center text-primary text-fs-head-xs">VERIFY WALLET</div>
              <div
                className="py-3 text-t-body-color text-center text-fs-body-md 
              quick-handler-body"
              >
                After successfully registering and providing your KYC information, wallet
                verification is the next step.
              </div>
              <div className="d-flex justify-content-center border-top-0">
                <Button
                  className="text-fs-head-sm py-2"
                  variant="primary"
                  disabled={checkUserWalletAccount(accounts)}
                  onClick={() => {
                    if (!checkUserWalletAccount(accounts)) {
                      wallet.connectWallet();
                    }
                  }}
                >
                  {checkUserWalletAccount(accounts) ? 'Verified' : 'Verify'}
                </Button>
              </div>
            </div>
          </Col>
        )}
        {isStakeMerxToken && (
          <Col
            id="stake-merx-token-col"
            ref={(newRef) => setStakeMerxTokenRef(newRef)}
            className="quick-handler-col"
            lg={isStakeMerxToken ? '3' : '4'}
            md="6"
            sm="12"
          >
            <div className="d-flex justify-content-center align-items-start">
              <img
                alt="sign-up-and-kyc"
                src={stakeMerxTokenImg.src}
                style={getImgStyle(stakeMerxTokenRef, stakeMerxTokenImg)}
                width={stakeMerxTokenImg.width}
                height={stakeMerxTokenImg.height}
              />
            </div>

            <div
              className="bg-white rounded pb-5 px-4 mx-1 shadow 
              quick-handler-container d-flex flex-column justify-content-end"
            >
              <div className="h6 mb-0 text-center text-primary text-fs-head-xs">LOCK MERX</div>
              <div
                className="py-3 text-t-body-color text-center text-fs-body-md 
              quick-handler-body"
              >
                Allocations in IDOs can be earned by locking MERX. Even if sales participation isn't
                your preference, locking could still yield benefits for you.
              </div>
              <div className="d-flex justify-content-center border-top-0">
                <Button
                  className="text-fs-head-sm py-2"
                  variant="primary"
                  disabled={!user}
                  onClick={() => {
                    if (user) {
                        history.push('/staking');
                    }
                  }}
                >
                  Active Locks
                </Button>
              </div>
            </div>
          </Col>
        )}
        <Col
          id="register-for-sale-col"
          ref={(newRef) => setRegisterForSaleRef(newRef)}
          className="quick-handler-col"
          lg={isStakeMerxToken ? '3' : '4'}
          md="6"
          sm="12"
        >
          <div className="d-flex justify-content-center align-items-start">
            <img
              alt="sign-up-and-kyc"
              src={registerForSaleImg.src}
              style={getImgStyle(registerForSaleRef, registerForSaleImg)}
              width={registerForSaleImg.width}
              height={registerForSaleImg.height}
            />
          </div>

          <div
            className="bg-white rounded pb-5 px-4 mx-1 shadow  
            quick-handler-container d-flex flex-column justify-content-end"
          >
            <div className="h6 mb-0 text-center text-primary text-fs-head-xs">
              REGISTER FOR SALE
            </div>
            <div className="py-3 text-t-body-color text-center text-fs-body-md quick-handler-body">
              During registration, confirm your intent to join. After it ends, no new registrations
              are accepted until the next sale.
            </div>
            <div className="d-flex justify-content-center border-top-0">
              <Button
                className="text-fs-head-sm py-2"
                variant="primary"
                onClick={() => {
                  history.push('/sales');
                }}
              >
                Active Sales
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}

function mapStateToProps(state) {
  return {
    user: state.userReducer.user,
    accounts: state.walletReducer.accounts,
  };
}

export default connect(mapStateToProps)(MercurXQuickHandler);
