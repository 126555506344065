/* eslint-disable max-len */
export const tripleCardInfo = {
  lp1stCard: {
    header: 'Customized Solutions',
    text: 'We understand that every project is unique, and we offer unique solutions to meet the different needs of your specific project for each industry.',
  },
  lp2ndCard: {
    header: 'Optimized for Success',
    text: "We offer tools and resources to optimize your web3 project's success, from project planning and development to launch and ongoing management.",
  },
  lp3rdCard: {
    header: 'Collaborative Approach',
    text: 'From project planning and development to initiation and ongoing management, we provide tools and resources to optimize the success of your Web3 project.',
  },
  sales1stCard: {
    header: 'Web3 Organizations',
    text: 'We work with a range of web3 organizations from startups to established companies, in various industries and sectors.',
  },
  sales2ndCard: {
    header: 'Growing Businesses',
    text: 'We help businesses achieve growth by offering effective and reliable web3 solutions that meet their needs.',
  },
  sales3rdCard: {
    header: 'Community Builders',
    text: 'Our solutions allow community builders to raise funds and create projects without worrying about security issues.',
  },
};
