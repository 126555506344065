import React from 'react';
import Subscribe from '../../components/MercurXSubscribe/Subscribe';
//import HomeSales from '../../components/MercurXHomeSales/HomeSales';
import './Whitepaper.scss';
import { Col, Container, Row } from 'react-bootstrap';
import MercurXIcons from '../../components/MercurXIcons/MercurXIcons';
import mercurxMiniIcon from '../../assets/img/logo/mercurx-logo-mini.png';
import mercurxWhitepaper from '../../assets/img/background/whitepaper.png';

function Whitepaper({ ...props }) {
  return (
    <Container>
      <div className="d-flex justify-content-center align-items-center">
        <MercurXIcons name="BiDownload" size={28} />
        <div className="text-fs-head-md ms-1">Download MercurX Whitepaper</div>
      </div>
      <div className="shadow rounded p-4 my-3">
        <Row>
          <Col
            sm="12"
            md="6"
            className="d-flex flex-column align-items-center justify-content-center"
          >
            <div className="d-flex align-items-center">
              <img alt="mercurx-mini-icon" src={mercurxMiniIcon} height={30} />
              <a
                href="https://launchpad.mercurx.com/api/whitepaper/en"
                className="text-fs-body-lg ms-1 text-dark text-decoration-underline"
              >
                MercurX Whitepaper (English-EN)
              </a>
            </div>
            <div className="d-flex align-items-center mt-4">
              <img alt="mercurx-mini-icon" src={mercurxMiniIcon} height={30} />
              <a
                href="https://launchpad.mercurx.com/api/whitepaper/tr"
                className="text-fs-body-lg ms-1 text-dark text-decoration-underline"
              >
                MercurX Whitepaper (Turkish-TR)
              </a>
            </div>
          </Col>
          <Col sm="12" md="6" className="d-flex justify-content-center">
            <img alt="mercurx-whitepaper" className='shadow' src={mercurxWhitepaper} height={400} />
          </Col>
        </Row>
      </div>
      <Subscribe />
    </Container>
  );
}

export default Whitepaper;
