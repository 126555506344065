import React from 'react';
import './MercurXProjectInfo.scss';

function ProjectTradingAnalytics({ ...props }) {
  const { project, history } = props;

  return (
    <div>
  
    </div>
  );
}

export default ProjectTradingAnalytics;