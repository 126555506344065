import React from 'react';
import { Col, Container, Nav, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import mercurxLogo from '../../assets/img/logo/mercurx-logo.png';
import { mainColors } from '../../helpers/colors';
import {
  walletAccountDetailModalAction,
  WalletConnectModalAction,
} from '../../store/wallet/walletActions';
import MercurXIcons from '../MercurXIcons/MercurXIcons';
import './MercurXFooter.scss';

function Footer({ ...props }) {
  const { WalletConnectModalRequest, walletAccountDetailModalRequest, accounts } = props;

  return (
    <Container className="d-flex flex-column my-5 py-5">
      <img className="mb-4 m-auto" alt="mercurx-logo" src={mercurxLogo} width={250} />
      <div className="text-center mb-4">
        <p className="text-dark-light mb-0">
          <a href="mailto:info@mercurx.com"> info@mercurx.com</a></p>
      </div>
      <Row className="border-top border-bottom py-4">
        <Col xs="6" md="3" className="my-3">
          <Nav.Link
            as={Link}
            to={{ pathname: 'https://www.linkedin.com/in/mercurx-undefined-22837b285/' }}
            // to="/#"
            target="_blank"
          >
            <MercurXIcons name="FaLinkedin" color={mainColors['dark-light']} size="56" />
          </Nav.Link>
        </Col>
        <Col xs="6" md="3" className="my-3">
          <Nav.Link
            as={Link}
            to={{ pathname: 'https://www.instagram.com/mercurxapp' }}
            // to="/#"
            target="_blank"
          >
            <MercurXIcons name="FaInstagram" color={mainColors['dark-light']} size="56" />
          </Nav.Link>
        </Col>
        <Col xs="6" md="3" className="my-3">
          <Nav.Link
            as={Link}
            to={{ pathname: 'https://www.twitter.com/mercurxapp' }}
            // to="/#"
            target="_blank"
          >
            <MercurXIcons name="FaTwitter" color={mainColors['dark-light']} size="56" />
          </Nav.Link>
        </Col>
        {/* <Col xs="6" md="2" className="my-2">
          <Nav.Link
            as={Link}
            to={{ pathname: 'https://www.facebook.com/mercurxapp' }}
            // to="/#"
            target="_blank"
          >
            <MercurXIcons name="FaFacebook" color={mainColors['dark-light']} size="56" />
          </Nav.Link>
        </Col> */}
        {/* <Col xs="6" md="2" className="my-2">
          <Nav.Link
            as={Link}
            to={{ pathname: 'https://www.youtube.com/mercurxapp' }}
            // to="/#"
            target="_blank"
          >
            <MercurXIcons name="FaYoutube" color={mainColors['dark-light']} size="56" />
          </Nav.Link>
        </Col> */}
        <Col xs="6" md="3" className="my-3">
          <Nav.Link
            as={Link}
            to={{ pathname: 'https://discord.gg/AqqgKPFDbQ' }}
            target="_blank"
          >
            <MercurXIcons name="FaDiscord" color={mainColors['dark-light']} size="56" />
          </Nav.Link>
        </Col>
      </Row>
      <Row className="text-center pt-5">
        <Col xs="6" md="3" className="my-2">
          <Nav.Link as={Link} className="text-dark-light text-fs-head-sm" to="/launchpad">
            Launchpad
          </Nav.Link>
        </Col>
        <Col xs="6" md="3" className="my-2">
          <Nav.Link as={Link} className="text-dark-light text-fs-head-sm" to="/sales">
            Sales
          </Nav.Link>
        </Col>
        <Col xs="6" md="3" className="my-2">
          <Nav.Link as={Link} className="text-dark-light text-fs-head-sm" to="/login">
            Login
          </Nav.Link>
        </Col>
        <Col xs="6" md="3" className="my-2">
          <Nav.Link
            className="text-dark-light text-fs-head-sm"
            onClick={() => {
              if (accounts?.[0]) {
                walletAccountDetailModalRequest(true);
              } else {
                WalletConnectModalRequest(true);
              }
            }}
          >
            Connect Wallet
          </Nav.Link>
        </Col>
        {/* <Col xs="6" md="2" className="mt-2">
          <Nav.Link as={Link} className="text-dark-light text-fs-head-sm" to="/contact">
            Contact
          </Nav.Link>
        </Col>
        <Col xs="6" md="2" className="mt-2">
          <Nav.Link as={Link} className="text-dark-light text-fs-head-sm" to="/faq">
            FAQ
          </Nav.Link>
        </Col> */}
      </Row>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    accounts: state.walletReducer.accounts,
    token: state.tokenReducer.token,
    project: state.projectReducer.project,
    balance_: state.walletReducer.balance_,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    walletAccountDetailModalRequest: (payload) => {
      dispatch(walletAccountDetailModalAction(payload));
    },
    WalletConnectModalRequest: (payload) => {
      dispatch(WalletConnectModalAction(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
