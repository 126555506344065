export const GET_STAKING_HISTORY_REQUEST = 'GET_STAKING_HISTORY_REQUEST';
export const GET_STAKING_HISTORY_DATA = 'GET_STAKING_HISTORY_DATA';
export const GET_STAKING_HISTORY_ERROR = 'GET_STAKING_HISTORY_ERROR';

export const GET_STAKING_STATISTICS_REQUEST = 'GET_STAKING_STATISTICS_REQUEST';
export const GET_STAKING_STATISTICS_DATA = 'GET_STAKING_STATISTICS_DATA';
export const GET_STAKING_STATISTICS_ERROR = 'GET_STAKING_STATISTICS_ERROR';

export const POST_STAKING_REQUEST = 'POST_STAKING_REQUEST';
export const POST_STAKING_DATA = 'POST_STAKING_DATA';
export const POST_STAKING_ERROR = 'POST_STAKING_ERROR';

export const PUT_STAKING_REQUEST = 'PUT_STAKING_REQUEST';
export const PUT_STAKING_DATA = 'PUT_STAKING_DATA';
export const PUT_STAKING_ERROR = 'PUT_STAKING_ERROR';

export const SET_STAKING_HISTORY_SORT_DATA = 'SET_TOKEN_SORT_DATA';
export const SORTING_STAKING_HISTORY = 'SORTING_STAKING_HISTORY';
