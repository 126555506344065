import * as types from './lockingActionTypes';

export const getLockingHistoryRequestAction = (payload) => {
  return {
    type: types.GET_LOCKING_HISTORY_REQUEST,
    payload,
  };
};

export const getLockingHistoryDataAction = (payload) => {
  return {
    type: types.GET_LOCKING_HISTORY_DATA,
    payload,
  };
};

export const getLockingHistoryErrorAction = (payload) => {
  return {
    type: types.GET_LOCKING_HISTORY_ERROR,
    payload,
  };
};

export const getLockingStatisticsRequestAction = (payload) => {
  return {
    type: types.GET_LOCKING_STATISTICS_REQUEST,
    payload,
  };
};

export const getLockingStatisticsDataAction = (payload) => {
  return {
    type: types.GET_LOCKING_STATISTICS_DATA,
    payload,
  };
};

export const getLockingStatisticsErrorAction = (payload) => {
  return {
    type: types.GET_LOCKING_STATISTICS_ERROR,
    payload,
  };
};

export const postLockingRequestAction = (payload) => {
  return {
    type: types.POST_LOCKING_REQUEST,
    payload,
  };
};

export const postLockingDataAction = (payload) => {
  return {
    type: types.POST_LOCKING_DATA,
    payload,
  };
};

export const postLockingErrorAction = (payload) => {
  return {
    type: types.POST_LOCKING_ERROR,
    payload,
  };
};

export const putLockingRequestAction = (payload) => {
  return {
    type: types.PUT_LOCKING_REQUEST,
    payload,
  };
};

export const putLockingDataAction = (payload) => {
  return {
    type: types.PUT_LOCKING_DATA,
    payload,
  };
};

export const putLockingErrorAction = (payload) => {
  return {
    type: types.PUT_LOCKING_ERROR,
    payload,
  };
};

export const sortLockingHistorySortData = (payload) => {
  return {
    type: types.SET_LOCKING_HISTORY_SORT_DATA,
    payload,
  };
};
export const sortingLockingHistoryAction = (payload) => {
  return {
    type: types.SORTING_LOCKING_HISTORY,
    payload,
  };
};
