import lpSignUpAndKYCImgSrc from '../assets/img/quick-handler-images/qh-lp-sign-up-and-kyc.png';
import lpVerifyWalletImgSrc from '../assets/img/quick-handler-images/qh-lp-verify-wallet.png';
import lpStakeMerxTokenImgSrc from '../assets/img/quick-handler-images/qh-lp-stake-merxtoken.png';
// eslint-disable-next-line max-len
import lpRegisterForProjectSrc from '../assets/img/quick-handler-images/qh-lp-register-for-project.png';
// eslint-disable-next-line max-len
import salesSignUpAndKYCImgSrc from '../assets/img/quick-handler-images/qh-s-sign-up-and-kyc.png';
import salesVerifyWalletImgSrc from '../assets/img/quick-handler-images/qh-s-verify-wallet.png';
// eslint-disable-next-line max-len
// import salesRegisterForSaleSrc from '../assets/img/quick-handler-images/qh-s-register-for-sale.png';

export const quickHandlerImages = {
  lpSignUpAndKYCImg: {
    src: lpSignUpAndKYCImgSrc,
    width: 78,
    height: 150,
  },
  lpVerifyWalletImg: {
    src: lpVerifyWalletImgSrc,
    width: 109,
    height: 150,
  },
  lpStakeMerxTokenImg: {
    src: lpStakeMerxTokenImgSrc,
    width: 160,
    height: 150,
  },
  lpRegisterForProject: {
    src: lpRegisterForProjectSrc,
    width: 80,
    height: 150,
  },
  salesSignUpAndKYCImg: { src: salesSignUpAndKYCImgSrc, width: 214, height: 150 },
  salesVerifyWalletImg: { src: salesVerifyWalletImgSrc, width: 120, height: 150 },
  salesRegisterForSale: { src: lpRegisterForProjectSrc, width: 102, height: 150 },
};
