
import * as alertActionTypes from '../store/alert/alertActionTypes';
import { put, call } from 'redux-saga/effects'; 
import { logoutSaga} from '../store/account/userSaga'

export function* errorHandler(error, history, href) {
  if (error?.response?.status === 403) {
    yield call(logoutSaga); 

    setTimeout(() => {
      window.location.reload();
    }, 3000);
    
    yield put({
      type: alertActionTypes.SET_ALERT,
      msg: {
        title: 'Session Expired!',
        text: 'Your session has expired. Please log in again to continue.',
        variant: 'danger',
        outTimeMS: 6000,
      },
    });
   
  } else if (error?.response?.status === 401 || 422 ) {

    return;
  } else {
    yield put({
      type: alertActionTypes.SET_ALERT,
      msg: {
        title: 'Error!',
        text: error.msg || 'An unknown error occurred.',
        variant: 'danger',
        outTimeMS: 6000,
      },
    });
  }
}
