import React, { useState } from 'react';
import { getProjectByID, getMercurxProjectRequestAction } from '../../store/project/projectActions';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { setWalletAccountData } from '../../store/wallet/walletActions';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
// eslint-disable-next-line max-len
import { checkAllConditionForStake,checkRegisterConditionForProject } from '../../helpers/verificationHelper';
import wallet from '../../helpers/wallet';
import detectEthereumProvider from '@metamask/detect-provider';
import './ProjectDetail.scss';
import MercurXProjectInfo from '../../components/MercurXProjectInfo/MercurXProjectInfo';
import MercurXIcons from '../../components/MercurXIcons/MercurXIcons';
import { swapTokenModalAction } from '../../store/project/projectActions';
import { buyTokenModalAction } from '../../store/token/tokenActions';
import { registrationTokenRequestAction } from '../../store/token/tokenActions';
import MercurXBuyTokenModal from '../../components/MercurXBuyTokenModal/MercurXBuyTokenModal';
import MercurXSwapTokenModal from '../../components/MercurXSwapTokenModal/MercurXSwapTokenModal';
import ProjectFlow from '../../components/MercurXProjectFlow/ProjectFlow';
import MercurXSpinner from '../../components/MercurXSpinner/MercurXSpinner';
import {
  accountDetailsRequestAction
} from '../../store/account/userActions';
export const checkUserVerified = (user) => {
  return user?.is_active?.toString().toLowerCase() === 'true';
};
export const checkUserKyc = (user) => {
  return user?.kyc[0]?.kyc_status === 'Approved';
};
function ProjectDetail({ ...props }) {
  const {
    project,
    accountDetailsRequest,
    mercurxProject,
    provider,
    accounts,
    ethereum,
    setWalletAccount,
    user,
    buyTokenModalRequest,
    registrationTokenRequest,
    swapTokenModalRequest,
    getMercurxProjectRequest,
    tierData,
  } = props;
  useEffect(() => {
    accountDetailsRequest();
  }, []);

  const item = props.project;

  const [stake, setStake] = useState(false);
  const [swap, setSwap] = useState(false);

  const [signature, setSignature] = useState('');
  const [error, setError] = useState('');
  const [chainId, setChainId] = useState();

  const [message, setMessage] = useState('');
  const [signedMessage, setSignedMessage] = useState('');
  const [verified, setVerified] = useState();
  const todayUTC = new Date().toISOString();
  const stakeSetup = () => {
    setStake(true);
  };
  const swapSetup = () => {
    setSwap(true);
  };
  const handleShow = () => {
    buyTokenModalRequest(true);
  };
  const handleRegistration = () => {
    const project_id = project?.id;
    try {
        const payload = {
          project_id,
        };
        registrationTokenRequest(payload);

      } catch (err) {
    }
  };
  const handleShowSwap = () => {
    swapTokenModalRequest(true);
  };
  const connectWallet = async () => {
    wallet.connectWallet();
  };

  const addMerxTokenFunction = async () => {
    try {
      const provider = await detectEthereumProvider();
      const wasAdded = await provider.sendAsync({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: item.token.address,
            symbol: item.token.symbol,
            decimals: item.token.decimals,
          },
        },
      });

      if (wasAdded) {
      } else {
      }
    } catch (error) {
    }
  };

  const refreshState = () => {
    // setWalletAccount(null);
    setChainId();
    // setNetwork('');
    setMessage('');
    setSignature('');
    setVerified(undefined);
  };

  const disconnect = async () => {
    wallet.disconnectWallet();
  };

  useEffect(() => {

    if (provider?.on) {
      const handleAccountsChanged = (newAccounts) => {
        if (accounts?.[0] !== newAccounts?.[0]) setWalletAccount(newAccounts);
      };
      const handleChainChanged = (_hexChainId) => {
        setChainId(_hexChainId);
      };

      const handleDisconnect = () => {
        disconnect();
      };

      provider.on('accountsChanged', handleAccountsChanged);
      provider.on('chainChanged', handleChainChanged);
      provider.on('disconnect', handleDisconnect);

      return () => {
        if (provider.removeListener) {
          provider.removeListener('accountsChanged', handleAccountsChanged);
          provider.removeListener('chainChanged', handleChainChanged);
          provider.removeListener('disconnect', handleDisconnect);
        }
      };
    }
  }, [provider]);

  useEffect(() => {
    const payload = {
      id: props.match.params.id,
    };
    props.getProjectByID(payload);
    getMercurxProjectRequest();

    return () => { };
  }, []);

  return (
    <>
      {!project || !mercurxProject ? (
        <MercurXSpinner />
      ) : (
        <Container className="mt-5 px-5">
          <Row>
            <Card className="project-detail-card col-md-12 col-sm-12">
              <Card.Header className="bg-white">
                <div
                  className="project-detail-name-div d-flex justify-content-between 
              align-items-center col-md-6 col-sm-4"
                >
                  {' '}
                  <Row className="">
                    <div>
                      <img
                        alt="project_logo"
                        src={process.env.REACT_APP_API_URL + '/projects/' + item.id + '/logo'}
                        height={92}
                        width={92}
                      />
                    </div>
                  </Row>
                  <Row className="col-md-12 col-lg-12 mx-0">
                    <Card.Title className="text-fs-head-lg"> {item.name}</Card.Title>
                    <Card.Text className="text-fs-body-sm">
                      {item?.token.symbol.toUpperCase()}</Card.Text>
                  </Row>
                </div>
                <div className="project-detail-price-div">
                  <Card.Text className="text-fs-body-sm mb-0">PRICE</Card.Text>
                  <Card.Title
                    className="text-fs-head-md 
                  mb-0"
                  >
                    {' '}
                    {item.token.price_in_usd.toLocaleString('tr-TR', {
                      style: 'currency',
                      currency: 'USD',
                      currencyDisplay: 'symbol',
                      useGrouping: true,
                      minimumFractionDigits: 4,
                    })}
                  </Card.Title>
                  <Card.Text className="text-fs-body-md">
                    {item.token.price_in_mercurx} MERX
                  </Card.Text>
                </div>
              </Card.Header>
              <Card.Body>
                <div className="project-detail-name-div">
                  <Card.Text className="text-fs-body-md">Round</Card.Text>

                  <Card.Title className="text-fs-head-md">{item.active_round}</Card.Title>
                </div>
                <div className="project-detail-price-div">
                  <Card.Text className="text-fs-body-md">Time Left</Card.Text>
                  <Card.Title className="text-fs-head-md">{item.round_time_left}</Card.Title>
                </div>
                <span>{error ? error.message : null}</span>
              </Card.Body>

              <div>
                <div className="progress mb-2 rounded-pill">
                  <span
                    className="progress-value 
                    text-t-body-color-light"
                  >
                    {item.percent_raised}%
                  </span>
                  <div
                    className="progress-bar 
                    rounded-pill"
                    style={{
                      width: item.percent_raised + '%',
                    }}
                  ></div>
                </div>
              </div>
              <Card.Body className="project-detail-card-body">
                <div className="project-detail-name-div">
                  <Card.Text>TOKEN DISTRIBUTION</Card.Text>
                  <Card.Title className="text-fs-head-md">
                    {' '}
                    {item.token.distribution.toLocaleString('tr-TR', {
                      useGrouping: true,
                      minimumSignificantDigits: 1,
                    })}
                  </Card.Title>
                </div>

                <div className="project-detail-price-div">
                  <Card.Text>TOTAL RAISED</Card.Text>
                  <Card.Title>
                    {item.total_raised.toLocaleString('tr-TR', {
                      style: 'currency',
                      currency: 'USD',
                      currencyDisplay: 'symbol',
                      useGrouping: true,
                      minimumFractionDigits: 4,
                    })}
                    /{' '}
                    {item.target_raised.toLocaleString('tr-TR', {
                      style: 'currency',
                      currency: 'USD',
                      currencyDisplay: 'symbol',
                      useGrouping: true,
                      minimumFractionDigits: 4,
                    })}
                  </Card.Title>
                </div>

                <span>{error ? error.message : null}</span>
              </Card.Body>
              <Card.Footer className="bg-white">
                <div className="project-detail-footer-left-div">
                  <Card.Title>Want to buy {item?.token.symbol.toUpperCase()} token ?</Card.Title>
                </div>
                <div className="project-detail-footer-right-div">
                  <Row>
                    <Col>
                      <Button
                        variant="primary"
                        onClick={addMerxTokenFunction}
                        className="token-button"
                      >
                        Add {item?.token.symbol.toUpperCase()}{' '}
                      </Button>
                    </Col>
                    {project?.round_registration.end_date 
                    > todayUTC ? (
                      <Col>
                        {checkRegisterConditionForProject(user, accounts) &&
                          project?.is_active == 'active'&&
                          !user.projects.some(project => project.project_id === item.id)
                          ? (
                          <Button
                            className="token-button"
                            variant="primary"
                            onClick={() => {
                              handleRegistration();
                            }}
                          >
                            Registration 
                          </Button>
                        ) : (
                          <Button className="token-button" variant="primary" disabled={true}>
                            Registration
                          </Button>
                        )}
                      </Col>
                    ) :
                      (
                        <Col>
                          {checkAllConditionForStake(user, accounts) &&
                            project?.is_active == 'active' ? (
                            <Button
                              className="token-button"
                              variant="primary"
                              onClick={() => {
                                stakeSetup();
                                handleShow();
                              }}
                            >
                              {item?.token.symbol.toUpperCase()} / BNB
                            </Button>
                          ) : (
                            <Button className="token-button" variant="primary" disabled={true}>
                              {item?.token.symbol.toUpperCase()} / BNB
                            </Button>
                          )}
                        </Col>
                      )
                      }
                    {item.token.symbol != 'MerX' ? (
                      <Col>
                      {project?.round_registration.end_date 
                      <=
                    todayUTC ? (
                      <Col>
                        <></>
                      </Col>
                    ) :
                      (
                        <Col>
                          {checkAllConditionForStake(user, accounts) &&
                          project?.is_active == 'active' ? (
                          <Button
                            className="token-button"
                            variant="primary"
                            onClick={() => {
                              swapSetup();
                              handleShowSwap();
                            }}
                          >
                           Swap {item.token.symbol.toUpperCase()} / MERX
                          </Button>
                        ) : (
                          <Button className="token-button" variant="primary" disabled={true}>
                            Swap {item.token.symbol.toUpperCase()} / MERX
                          </Button>
                        )}
                        </Col>
                      )}
                      </Col>
                    ) : (
                      <></>
                    )}
                  </Row>
                </div>
              </Card.Footer>
            </Card>
          </Row>
          <div className="mt-3">
            <Row>
              <Col>
                <div className="border rounded p-2">
                  <div className="d-flex align-items-center border-bottom-0">
                    {user ? (
                      <MercurXIcons name="MdDone" color="#28a745" size="24" />
                    ) : (
                      <MercurXIcons name="MdPriorityHigh" color="#ffc107" size="24" />
                    )}
                    <span className="h6 mb-0 mx-auto">
                      {user ? 'Account Enabled' : 'Account Disabled'}
                    </span>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="border rounded p-2">
                  <div className="d-flex align-items-center border-bottom-0">
                    {accounts ? (
                      <MercurXIcons name="MdDone" color="#28a745" size="24" />
                    ) : (
                      <MercurXIcons name="MdPriorityHigh" color="#ffc107" size="24" />
                    )}
                    <span className="h6 mb-0 mx-auto">
                      {accounts ? 'Wallet Enabled' : 'Wallet Disabled'}
                    </span>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="border rounded p-2">
                  <div className="d-flex align-items-center border-bottom-0">
                    {checkUserVerified(user) ? (
                      <MercurXIcons name="MdDone" color="#28a745" size="24" />
                    ) : (
                      <MercurXIcons name="MdPriorityHigh" color="#ffc107" size="24" />
                    )}
                    <span className="h6 mb-0 mx-auto">
                      {checkUserVerified(user) ? 'Mail Verified' : 'Mail Not Verified'}
                    </span>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="border rounded p-2">
                  <div className="d-flex align-items-center border-bottom-0">
                    {checkUserKyc(user) ? (
                      <MercurXIcons name="MdDone" color="#28a745" size="24" />
                    ) : (
                      <MercurXIcons name="MdPriorityHigh" color="#ffc107" size="24" />
                    )}
                    <span className="h6 mb-0 mx-auto">
                      {checkUserKyc(user) ? 'Kyc Approved' : 'Kyc Unapproved'}
                    </span>
                  </div>
                </div>
              </Col>
            </Row>

            {checkAllConditionForStake(user, accounts) && stake ? (
              <MercurXBuyTokenModal />
            ) : (
              <div></div>
            )}
            {checkAllConditionForStake(user, accounts) && swap ? (
              <MercurXSwapTokenModal />
            ) : (
              <div></div>
            )}
          </div>
          <ProjectFlow {...props} />
          <MercurXProjectInfo {...props} />
        </Container>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    provider: state.walletReducer.provider,
    ethereum: state.walletReducer.ethereum,
    accounts: state.walletReducer.accounts,
    user: state.userReducer.user,
    project: state.projectReducer.project,
    mercurxProject: state.projectReducer.mercurxProject,
    tierData: state.tierReducer.tierData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    // connectWalletRequest: (payload) => {
    //   dispatch(connectWallet(payload));
    // },
    accountDetailsRequest: (payload) => {
      dispatch(accountDetailsRequestAction(payload));
    },
    setWalletAccount: (payload) => {
      dispatch(setWalletAccountData(payload));
    },
    getProjectByID: (payload) => {
      dispatch(getProjectByID(payload));
    },
    buyTokenModalRequest: (payload) => {
      dispatch(buyTokenModalAction(payload));
    },
    registrationTokenRequest: (payload) => {
      dispatch(registrationTokenRequestAction(payload));
    },
    swapTokenModalRequest: (payload) => {
      dispatch(swapTokenModalAction(payload));
    },
    getMercurxProjectRequest: (payload) => {
      dispatch(getMercurxProjectRequestAction(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetail);
