import abExpertiseImgSrc from '../assets/img/quick-handler-images/about-card-expertise.png';
// eslint-disable-next-line max-len
import abTransparencyImgSrc from '../assets/img/quick-handler-images/about-card-transparency.png';
// eslint-disable-next-line max-len
import abCustomerSatisfactionImgSrc from '../assets/img/quick-handler-images/about-card-customer_satisfaction.png';


export const aboutUsImages = {
  abExpertiseImg: {
    src: abExpertiseImgSrc,
    width: 128,
    height: 150,
  },
  abTransparencyImg: {
    src: abTransparencyImgSrc,
    width: 137,
    height: 150,
  },
  abCustomerSatisfactionImg: {
    src: abCustomerSatisfactionImgSrc,
    width: 136,
    height: 150,
  },
};
