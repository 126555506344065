import * as types from './stakingActionTypes';

export const getStakingHistoryRequestAction = (payload) => {
  return {
    type: types.GET_STAKING_HISTORY_REQUEST,
    payload,
  };
};

export const getStakingHistoryDataAction = (payload) => {
  return {
    type: types.GET_STAKING_HISTORY_DATA,
    payload,
  };
};

export const getStakingHistoryErrorAction = (payload) => {
  return {
    type: types.GET_STAKING_HISTORY_ERROR,
    payload,
  };
};

export const getStakingStatisticsRequestAction = (payload) => {
  return {
    type: types.GET_STAKING_STATISTICS_REQUEST,
    payload,
  };
};

export const getStakingStatisticsDataAction = (payload) => {
  return {
    type: types.GET_STAKING_STATISTICS_DATA,
    payload,
  };
};

export const getStakingStatisticsErrorAction = (payload) => {
  return {
    type: types.GET_STAKING_STATISTICS_ERROR,
    payload,
  };
};

export const postStakingRequestAction = (payload) => {
  return {
    type: types.POST_STAKING_REQUEST,
    payload,
  };
};

export const postStakingDataAction = (payload) => {
  return {
    type: types.POST_STAKING_DATA,
    payload,
  };
};

export const postStakingErrorAction = (payload) => {
  return {
    type: types.POST_STAKING_ERROR,
    payload,
  };
};

export const putStakingRequestAction = (payload) => {
  return {
    type: types.PUT_STAKING_REQUEST,
    payload,
  };
};

export const putStakingDataAction = (payload) => {
  return {
    type: types.PUT_STAKING_DATA,
    payload,
  };
};

export const putStakingErrorAction = (payload) => {
  return {
    type: types.PUT_STAKING_ERROR,
    payload,
  };
};

export const sortStakingHistorySortData = (payload) => {
  return {
    type: types.SET_STAKING_HISTORY_SORT_DATA,
    payload,
  };
};
export const sortingStakingHistoryAction = (payload) => {
  return {
    type: types.SORTING_STAKING_HISTORY,
    payload,
  };
};
