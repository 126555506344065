import { takeEvery, call, put, all } from 'redux-saga/effects';

import * as types from './tokenActionTypes';
import * as actions from './tokenActions';
import * as alert from '../alert/alertActions';
import * as endpoints from '../../services/endpoints';
import { errorHandler } from '../../helpers/errorHandler';

function* getBNBSaga(action) {
  try {
    const { data } = yield call(endpoints.getBNB);
    yield put(actions.getBNBData(data));
  } catch (e) {
    yield call(errorHandler, e, action.history, action.href);
    
    yield put(actions.getBNBError(e));
  }
}

function* getTokenSaga(action) {
  try {
    const { data } = yield call(endpoints.getProjects);
    yield put(actions.getTokensData(data));
    yield put(actions.sortingTokensAction());
  } catch (e) {
    yield call(errorHandler, e, action.history, action.href);
  
    yield put(actions.getTokensError(e));
  }
}

function* getTokenByIDSaga(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(endpoints.getTokenByID, id);
    yield put(actions.getTokenByIDData(data));
  } catch (e) {
    yield call(errorHandler, e, action.history, action.href);
    
    yield put(actions.getTokenByIDError(e));
  }
}

function* addToken(action) {
  try {
    const { data } = yield call(endpoints.addToken, action.data);

    yield put(actions.tokenAddedAction(data));
    yield put(
      alert.setAlertAction({
        title: 'Success!',
        text: 'Token Added!',
        variant: 'success',
      }),
    );
  } catch (e) {
   
}
}
function* registrationTokenSaga({payload}) {
  try {
    const { data } = yield call(endpoints.registrationToken, payload);
    yield put (actions.registrationTokenDataAction(data))
    yield put(
      alert.setAlertAction({
        title: 'Success!',
        text: 'Registration successfully!',
        variant: 'success',
      }),    
    );
} catch (e) {
  if (e.response && e.response.status === 400) {
    yield put(
      alert.setAlertAction({
        title: 'Error!',
        text: 'Record already exists!!!',
        variant: 'danger',
        outTimeMS: 6000,
      }),
    );
  } else {
    yield put(
      alert.setAlertAction({
        title: 'Error!',
        text: e.msg,
        variant: 'danger',
        outTimeMS: 6000,
      }),
    );
    yield put(actions.registrationTokenErrorAction(e));
  }
}
}

function* watchGetBNB() {
  yield takeEvery(types.GET_BNB_REQUEST, getBNBSaga);
}

function* watchGetTokens() {
  yield takeEvery(types.GET_TOKENS_REQUEST, getTokenSaga);
}

function* watchGetTokenByID() {
  yield takeEvery(types.GET_TOKEN_REQUEST, getTokenByIDSaga);
}

function* watchAddToken() {
  yield takeEvery(types.ADD_TOKEN, addToken);
}
function* watchRegistrationToken() {
  yield takeEvery(types.REGISTRATION_TOKEN_REQUEST, registrationTokenSaga);
}

export function* tokenSaga() {
  yield all([watchAddToken(), 
    watchGetTokenByID(), watchGetTokens(), watchGetBNB(), watchRegistrationToken()]);
}
