import * as types from './tierActionTypes';

const initialState = {
  tierData: null,
  error: {
    type: null,
    data: null,
  },
};

export const tierReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_TIER_DATA:
      return {
        ...state,
        tierData: action?.payload ? { ...action?.payload } : null,
      };
    case types.GET_TIER_ERROR:
      return {
        ...state,
        error: {
          type: types.GET_TIER_ERROR,
          data: action.payload,
        },
      };
    default:
      return state;
  }
};
