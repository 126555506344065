import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import './MercurXAboutUs.scss';
import { aboutUsImages } from '../../helpers/aboutUsImages';

function MercurXAboutUs({ ...props }) {
  const { user, accounts, history, className } = props;

  const [signUpAndKycRef, setSignUpAndKycRef] = useState(null);
  const [verifyWalletRef, setVerifyWalletRef] = useState(null);
  const [stakeMerxTokenRef, setStakeMerxTokenRef] = useState(null);
  const [registerForSaleRef, setRegisterForSaleRef] = useState(null);

  const [size, setSize] = useState([0, 0]);

  function updateSize() {
    setSize([window.innerWidth, window.innerHeight]);
  }

  useEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, []);

  function getImgStyle(ref, img) {
    const halfHeight = Math.floor(img?.height / 2);
    return {
      position: 'absolute',
      top: ref?.offsetTop - halfHeight,
    };
  }

  return (
    <>
      <Row className={className}>
        <Col
          id="consulting"
          ref={(newRef) => setSignUpAndKycRef(newRef)}
          className="about-col"
          lg={'4'}
          md="6"
          sm="12"
        >
          <div className="d-flex justify-content-center align-items-start">
            <img
              alt="sign-up-and-kyc"
              src={aboutUsImages.abExpertiseImg.src}
              style={getImgStyle(signUpAndKycRef, aboutUsImages.abExpertiseImg)}
              width={aboutUsImages.abExpertiseImg.width}
              height={aboutUsImages.abExpertiseImg.height}
            />
          </div>

          <div
            className="bg-white rounded pb-5 px-4 mx-1 shadow 
            about-container d-flex flex-column justify-content-end"
          >
            <div className="h6 mb-0 text-center text-primary text-fs-head-xs">Expertise</div>
            <div
              className="py-3 text-t-body-color text-center text-fs-body-md 
              about-body"
            >
              Our experienced professionals have the expertise to guide you through launching your
              web3 project.
            </div>
          </div>
        </Col>

        <Col
          id="project-delivery"
          ref={(newRef) => setVerifyWalletRef(newRef)}
          className="about-col"
          lg={'4'}
          md="6"
          sm="12"
        >
          <div className="d-flex justify-content-center align-items-start">
            <img
              alt="sign-up-and-kyc"
              src={aboutUsImages.abTransparencyImg.src}
              style={getImgStyle(verifyWalletRef, aboutUsImages.abTransparencyImg)}
              width={aboutUsImages.abTransparencyImg.width}
              height={aboutUsImages.abTransparencyImg.height}
            />
          </div>

          <div
            className="bg-white rounded pb-5 px-4 mx-1 shadow 
              about-container d-flex flex-column justify-content-end"
          >
            <div className="h6 mb-0 text-center text-primary text-fs-head-xs">Transparency</div>
            <div
              className="py-3 text-t-body-color text-center text-fs-body-md 
              about-body"
            >
              We are transparent in our approach and believe in building trust with our clients by
              keeping them informed every step of the way.
            </div>
          </div>
        </Col>

        <Col
          id="launch-your-token"
          ref={(newRef) => setStakeMerxTokenRef(newRef)}
          className="about-col"
          lg={'4'}
          md="6"
          sm="12"
        >
          <div className="d-flex justify-content-center align-items-start">
            <img
              alt="sign-up-and-kyc"
              src={aboutUsImages.abCustomerSatisfactionImg.src}
              style={getImgStyle(stakeMerxTokenRef, aboutUsImages.abCustomerSatisfactionImg)}
              width={aboutUsImages.abCustomerSatisfactionImg.width}
              height={aboutUsImages.abCustomerSatisfactionImg.height}
            />
          </div>

          <div
            className="bg-white rounded pb-5 px-4 mx-1 shadow 
              about-container d-flex flex-column justify-content-end"
          >
            <div className="h6 mb-0 text-center text-primary text-fs-head-xs">
              Customer Satisfaction
            </div>
            <div
              className="py-3 text-t-body-color text-center text-fs-body-md 
              about-body"
            >
              Your satisfaction is our priority and we want you to have the best work experience
              possible.
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}

function mapStateToProps(state) {
  return {
    user: state.userReducer.user,
    accounts: state.walletReducer.accounts,
  };
}

export default connect(mapStateToProps)(MercurXAboutUs);
