import React from 'react';
import { connect } from 'react-redux';
import './MercurXLoadingFull.scss';
import { Spinner } from 'react-bootstrap';

const MercurXLoadingFull = (props) => {
  const { isLoading, loadingText } = props.fullLoading;

  if (isLoading) {
    return (
        <div className='mercurx-loading-full-container'>
            <div className='d-flex align-items-center bg-white rounded p-2'>
                <Spinner animation='border' size='sm' variant='primary'/>
                <span className='ms-1'>{loadingText}</span>
            </div>
        </div>
      );
  } else {
    return <></>
  }
  
};

const mapStateToProps = (state) => {
  return {
    fullLoading: state.loadingReducer.fullLoading
  };
};

export default connect(mapStateToProps)(MercurXLoadingFull);
