import * as types from './tierActionTypes';

export const getTierRequestAction = (payload) => {
  return {
    type: types.GET_TIER_REQUEST,
    payload,
  };
};

export const getTierDataAction = (payload) => {
  return {
    type: types.GET_TIER_DATA,
    payload,
  };
};

export const getTierErrorAction = (payload) => {
  return {
    type: types.GET_TIER_ERROR,
    payload,
  };
};