import axios from 'axios';

const getToken = async () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const token = user?.token;

  if (token) {
    return 'Bearer ' + token.replace(/"/g, '');
  }
  return ''; 
};

const getTempToken = async () => {
  const tempToken = localStorage.getItem('temp_token');

  if (tempToken) {
    return 'Bearer ' + tempToken.replace(/"/g, '');
  }
  return ''; 
};

export const axiosGet = async (options) => {
  let fullApiPath = `${process.env.REACT_APP_API_URL}${options.endpoint}`;
  const token = await getToken();
  let res = axios.get(fullApiPath, {
    params: options.body,
    headers: {
      'Content-Type': 'application/json',
      ...(token && { Authorization: token }),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      timezoneOffset: new Date().getTimezoneOffset(),
    },
  });
  return res;
};


export const axiosPost = async (options) => {
  
  if(options.endpoint.startsWith('/verify_2fa/')){
    let fullApiPath = `${process.env.REACT_APP_API_URL}${options.endpoint}`;
    const token = await getTempToken();
  
    let res = await axios.post(fullApiPath, options.body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ,
      },
    });
  
    return res;
  }
  let fullApiPath = `${process.env.REACT_APP_API_URL}${options.endpoint}`;
  const token = await getToken();

  let res = await axios.post(fullApiPath, options.body, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ,
    },
  });

  return res;
};

export const axiosDelete = async (options) => {
  let fullApiPath = `${process.env.REACT_APP_API_URL}${options.endpoint}`;
  const token = await getToken();

  let res = await axios.delete(fullApiPath, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ,
    },
    data: options.body,
  });

  return res;
};

export const axiosPut = async (options) => {
  let fullApiPath = `${process.env.REACT_APP_API_URL}${options.endpoint}`;
  const token = await getToken();

  let res = await axios.put(fullApiPath, options.body, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ,
    },
  });
  return res;
};
