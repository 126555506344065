import { takeEvery, all, call, put } from 'redux-saga/effects';

import * as types from './tierActionTypes';
import * as actions from './tierActions';
// import * as alert from '../alert/alertActions';
import * as endpoints from '../../services/endpoints';
import { errorHandler } from '../../helpers/errorHandler';

function* getTierSaga(action) {
  try {
    const { data } = yield call(endpoints.getTier, action.payload.id);
    yield put(actions.getTierDataAction(data));
  } catch (e) {
    yield call(errorHandler, e, action.history, action.href);
    yield put(actions.getTierErrorAction(e));
  }
}
//Watcher Sagas
function* watchGetTierHistory() {
  yield takeEvery(types.GET_TIER_REQUEST, getTierSaga);
}

export function* tierSaga() {
  yield all([watchGetTierHistory()]);
}
