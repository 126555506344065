import {
  LOCKING_HISTORY_KEYS,
  LOCKING_HISTORY_SORT_TYPES,
  sortKeys,
} from '../../pages/Locking/lockingConstants';
import * as types from './lockingActionTypes';

const initialState = {
  //dummy locking history data
  lockingHistoryData: null,
  lockingStatisticsData: null,
  lockingHistorySortData: {
    sortType: LOCKING_HISTORY_SORT_TYPES.ASCENDING,
    sortKey: LOCKING_HISTORY_KEYS.NAME,
  },
  postedLock: null,
  error: {
    type: null,
    data: null,
  },
};

export const lockingReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LOCKING_HISTORY_DATA:
      return {
        ...state,
        lockingHistoryData: action?.payload ? [...action?.payload] : null,
      };
    case types.GET_LOCKING_HISTORY_ERROR:
      return {
        ...state,
        error: {
          type: types.GET_LOCKING_HISTORY_ERROR,
          data: action.payload,
        },
      };
    case types.GET_LOCKING_STATISTICS_DATA:
      return {
        ...state,
        lockingStatisticsData: action?.payload ? { ...action?.payload } : null,
      };
    case types.GET_LOCKING_STATISTICS_ERROR:
      return {
        ...state,
        error: {
          type: types.GET_LOCKING_STATISTICS_ERROR,
          data: action.payload,
        },
      };
    case types.POST_LOCKING_DATA:
      return {
        ...state,
        postedLock: action?.paylaod ? { ...action?.payload } : null,
      };
    case types.POST_LOCKING_ERROR:
      return {
        ...state,
        error: {
          type: types.POST_LOCKING_ERROR,
          data: action.payload,
        },
      };

    case types.PUT_LOCKING_DATA:
      return {
        ...state,
        putLock: action?.paylaod ? { ...action?.payload } : null,
      };
    case types.PUT_LOCKING_ERROR:
      return {
        ...state,
        error: {
          type: types.PUT_LOCKING_ERROR,
          data: action.payload,
        },
      };
    case types.SET_LOCKING_HISTORY_SORT_DATA:
      return {
        ...state,
        lockingHistorySortData: { ...action.payload },
      };
    case types.SORTING_LOCKING_HISTORY:
      const selectedKey = sortKeys[state.lockingHistorySortData.sortKey].key;
      const lockingHistoryData = state.lockingHistoryData;
      const sortedLockingHistoryData = lockingHistoryData?.sort((a, b) => {
        if (state.lockingHistorySortData.sortType === LOCKING_HISTORY_SORT_TYPES.ASCENDING) {
          return a[selectedKey]
            ?.toString()
            .toLowerCase()
            .localeCompare(b[selectedKey]?.toString().toLowerCase());
        } else {
          return b[selectedKey]
            ?.toString()
            .toLowerCase()
            .localeCompare(a[selectedKey]?.toString().toLowerCase());
        }
      });

      return {
        ...state,
        lockingHistoryData: sortedLockingHistoryData ? [...sortedLockingHistoryData] : null,
      };
    default:
      return state;
  }
};
