export const mainColors = {
  primary: '#097875',
  'primary-light': '#09787580',
  secondary: '#000',
  'secondary-light': '#00000080',
  tertiary: '#043E3C',
  'tertiary-light': '#043E3C80',
  't-head-color': '#0f0f0f',
  't-head-color-light': '#868686',
  't-body-color': '#727272',
  't-body-color-light': '#b8b8b8',
  light: '#e6e6e0',
  'ultra-light': '#e6e6e0',
  dark: '#000105',
  'dark-light': '#7f7f81',
};