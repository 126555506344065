import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import './MercurXOfferCards.scss';
import { offerCardImages } from '../../helpers/offerCardImages';

function MercurXQuickHandler({ ...props }) {
  const { user, accounts, history, className } = props;

  const [signUpAndKycRef, setSignUpAndKycRef] = useState(null);
  const [verifyWalletRef, setVerifyWalletRef] = useState(null);
  const [stakeMerxTokenRef, setStakeMerxTokenRef] = useState(null);
  const [registerForSaleRef, setRegisterForSaleRef] = useState(null);

  const [size, setSize] = useState([0, 0]);

  function updateSize() {
    setSize([window.innerWidth, window.innerHeight]);
  }

  useEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, []);

  function getImgStyle(ref, img) {
    const halfHeight = Math.floor(img?.height / 2);
    return {
      position: 'absolute',
      top: ref?.offsetTop - halfHeight,
    };
  }

  return (
    <>
      <Row className={className}>
        <Col
          id="consulting"
          ref={(newRef) => setSignUpAndKycRef(newRef)}
          className="offer-col"
          lg={'4'}
          md="6"
          sm="12"
        >
          <div className="d-flex justify-content-center align-items-start">
            <img
              alt="sign-up-and-kyc"
              src={offerCardImages.ocConsultingImg.src}
              style={getImgStyle(signUpAndKycRef, offerCardImages.ocConsultingImg)}
              width={offerCardImages.ocConsultingImg.width}
              height={offerCardImages.ocConsultingImg.height}
            />
          </div>

          <div
            className="bg-white rounded pb-5 px-4 mx-1 shadow 
              offer-container d-flex flex-column justify-content-end"
          >
            <div className="h6 mb-0 text-center text-primary text-fs-head-xs">Consultation</div>
            <div
              className="py-3 text-t-body-color text-center text-fs-body-md 
              offer-body"
            >
              We seamlessly facilitate your transition from Web 2.0 to Web 3.0, enabling an
              end-to-end flow in this cutting-edge digital metamorphosis.
            </div>
          </div>
        </Col>

        <Col
          id="project-delivery"
          ref={(newRef) => setVerifyWalletRef(newRef)}
          className="offer-col"
          lg={'4'}
          md="6"
          sm="12"
        >
          <div className="d-flex justify-content-center align-items-start">
            <img
              alt="sign-up-and-kyc"
              src={offerCardImages.ocProjectDeliveryImg.src}
              style={getImgStyle(verifyWalletRef, offerCardImages.ocProjectDeliveryImg)}
              width={offerCardImages.ocProjectDeliveryImg.width}
              height={offerCardImages.ocProjectDeliveryImg.height}
            />
          </div>

          <div
            className="bg-white rounded pb-5 px-4 mx-1 shadow 
              offer-container d-flex flex-column justify-content-end"
          >
            <div className="h6 mb-0 text-center text-primary text-fs-head-xs">
              Tailored Web 3.0 Project Delivery
            </div>
            <div
              className="py-3 text-t-body-color text-center text-fs-body-md 
              offer-body"
            >
              We architect your Web 3.0 project with precision, delivering a turnkey solution primed
              for your digital success.
            </div>
          </div>
        </Col>

        <Col
          id="launch-your-token"
          ref={(newRef) => setStakeMerxTokenRef(newRef)}
          className="offer-col"
          lg={'4'}
          md="6"
          sm="12"
        >
          <div className="d-flex justify-content-center align-items-start">
            <img
              alt="sign-up-and-kyc"
              src={offerCardImages.ocLaunchYourTokenImg.src}
              style={getImgStyle(stakeMerxTokenRef, offerCardImages.ocLaunchYourTokenImg)}
              width={offerCardImages.ocLaunchYourTokenImg.width}
              height={offerCardImages.ocLaunchYourTokenImg.height}
            />
          </div>

          <div
            className="bg-white rounded pb-5 px-4 mx-1 shadow 
              offer-container d-flex flex-column justify-content-end"
          >
            <div className="h6 mb-0 text-center text-primary text-fs-head-xs">
              Launch Your Token
            </div>
            <div
              className="py-3 text-t-body-color text-center text-fs-body-md 
              offer-body"
            >
              Launching a token is hard. We have helped of companies avoid pre- and post-launch
              business, legal, and technological issues.
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}

function mapStateToProps(state) {
  return {
    user: state.userReducer.user,
    accounts: state.walletReducer.accounts,
  };
}

export default connect(mapStateToProps)(MercurXQuickHandler);
