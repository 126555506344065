export const GET_LOCKING_HISTORY_REQUEST = 'GET_LOCKING_HISTORY_REQUEST';
export const GET_LOCKING_HISTORY_DATA = 'GET_LOCKING_HISTORY_DATA';
export const GET_LOCKING_HISTORY_ERROR = 'GET_LOCKING_HISTORY_ERROR';

export const GET_LOCKING_STATISTICS_REQUEST = 'GET_LOCKING_STATISTICS_REQUEST';
export const GET_LOCKING_STATISTICS_DATA = 'GET_LOCKING_STATISTICS_DATA';
export const GET_LOCKING_STATISTICS_ERROR = 'GET_LOCKING_STATISTICS_ERROR';

export const POST_LOCKING_REQUEST = 'POST_LOCKING_REQUEST';
export const POST_LOCKING_DATA = 'POST_LOCKING_DATA';
export const POST_LOCKING_ERROR = 'POST_LOCKING_ERROR';

export const PUT_LOCKING_REQUEST = 'PUT_LOCKING_REQUEST';
export const PUT_LOCKING_DATA = 'PUT_LOCKING_DATA';
export const PUT_LOCKING_ERROR = 'PUT_LOCKING_ERROR';

export const SET_LOCKING_HISTORY_SORT_DATA = 'SET_TOKEN_SORT_DATA';
export const SORTING_LOCKING_HISTORY = 'SORTING_LOCKING_HISTORY';
