import React, { useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import './MercurXProjectInfo.scss';

function ProjectAboutTheProject({ ...props }) {
  const { project, history } = props;
  const projectAboutContentRef = useRef(null);

  function scrollToContent(id) {
  }

  return (
    <Row>
      <Col
        lg="3"
        className="d-none d-lg-flex flex-column 
        project-about-sidebar text-fs-head-xxs text-t-body-color"
      >
        <span
          className="mt-1 project-about-link"
          onClick={() => scrollToContent('project-about-content-0')}
        >
          Table of Contents
        </span>
        <span
          className="mt-1 project-about-link"
          onClick={() => scrollToContent('project-about-content-1')}
        >
          1. Mercurx
        </span>
        {/* <span
          className="mt-1 project-about-link"
          onClick={() => scrollToContent('project-about-content-2')}
        >
          2. Heroes, Classes, and Abilities
        </span>
        <span
          className="mt-1 project-about-link"
          onClick={() => scrollToContent('project-about-content-3')}
        >
          3. Gameplay
        </span>
        <span
          className="mt-1 project-about-link"
          onClick={() => scrollToContent('project-about-content-4')}
        >
          4. Token Model
        </span>
        <span
          className="mt-1 project-about-link"
          onClick={() => scrollToContent('project-about-content-5')}
        >
          5. Esports
        </span>
        <span
          className="mt-1 project-about-link"
          onClick={() => scrollToContent('project-about-content-6')}
        >
          6. The ideal game
        </span>
        <span
          className="mt-1 project-about-link"
          onClick={() => scrollToContent('project-about-content-7')}
        >
          7. Tokenomics
        </span>
        <span
          className="mt-1 project-about-link"
          onClick={() => scrollToContent('project-about-content-8')}
        >
          8. Team
        </span>
        <span
          className="mt-1 project-about-link"
          onClick={() => scrollToContent('project-about-content-9')}
        >
          9. Quote About Working with MercurX
        </span>
        <span
          className="mt-1 project-about-link"
          onClick={() => scrollToContent('project-about-content-10')}
        >
          10. Partners
        </span> */}
      </Col>
      <Col lg="9" className="project-about-content-container" ref={projectAboutContentRef}>
        <img
          id="project-about-content-0"
          className="project-about-img"
          alt="project-img"
          src={process.env.REACT_APP_API_URL + '/projects/' + project.id + '/image'}
          style={{ width: '880px', height: '250px' }}
        />
        <div id="project-about-content-1" className="mt-2">
          <div className="text-fs-head-xxs my-2">SUMMARY</div>
          <div className="text-fs-body-md text-t-body-color">
            MercurX is a decentralized platform for different size of investors who wants to invest
            blockchain based crypto currencies. Platform members can generate new investment
            instruments and provide financing in an easy, flexible and transparent environment.
            Cryptocurrency investors generally decide what and how much to invest by focusing on 3
            big problems. These 3 problems are transparency, sustainability and high return.
          </div>
        </div>
        <div id="project-about-content-2" className="mt-2">
          <div className="text-fs-body-md text-t-body-color">
            Crypto-assets issued to date have generally met with investors behind closed doors, with
            the very high portion of the return occurring in the segment from those
            behind-the-scenes talks to the Initial Public Offering. Small investors, on the other
            hand, were generally not aware of these investments at this first stage.Those who knew
            were having difficulty in meeting the prerequisites to participate in these investments.
            With the development of platforms with transparent and shared decision mechanisms, small
            investors started to find a place in the market and earn money from their investments in
            a fair way. After that, The sustainability became the next problem. Now, both
            transparent and sustainable platforms have emerged as a need.
          </div>
        </div>
        <div id="project-about-content-3" className="mt-2">
          <div className="text-fs-body-md text-t-body-color">
            MercurX is committed to providing a decentralized, transparent, reliable and sustainable
            environment for the solution of the 3 main problems mentioned above. In this context, we
            offer investors a platform where investment records are kept safely, together with the
            reliable structure of the blockchain. Investors can transparently negotiate more
            favorable terms and maximize the return on their investment on this platform. Using this
            platform, MercurX customers can create crypto assets and bring them together with
            investors. All these steps take place in a completely safe and transparent manner. The
            historical records of these transactions are also securely stored with the blockchain
            method. Unlike other platforms, MercurX will also focus important sectors besides De-Fi,
            Gaming and Metaverse space by supporting, offering and prioritization of those projects.
            Example of the sectors are Energy, Tourism, Manufacturing and Retail. Not only we will
            offer projects in these sectors, MercurX will also create an awareness about these
            projects and support the use cases of the projects in both enterprise and crypto
            platforms.
          </div>
        </div>
        <div id="project-about-content-4" className="mt-2">
          <div className="text-fs-body-md text-t-body-color">
            MercurX has its own token decentralized economy. The MercurX Token(MERX) is central
            token that facilitate the economic model of MercurX, as it serves
            <br />
            <br />
            * How tokens will be circulated (e.g., token distribution, allocations,
            re-distributions, etc.)
            <br />
            * The rights, incentives, mechanisms, governance, access and utilities are formulated
            <br />
          </div>
        </div>
        <div id="project-about-content-5" className="mt-2">
          <div className="text-fs-body-md text-t-body-color">
            MercurX token economics is a self-organized, early-supporter and long-term incentivized
            model where we deploy various game theory and mechanism design approaches to secure
            long-lasting sustainability. As MercurX aims to provide a trusted bridge between
            business incentives and consumers; MercurX Token will enable all shareholders through
            their journey on MercurX. For both project owners and consumers; to be able to
            participate in listed IDO/ICO projects for a fairly and delicately defined allocation
            mechanism, MercurX Token will be used and utilized in this process.
          </div>
        </div>
        <div id="project-about-content-6" className="mt-2">
          <div className="text-fs-body-md text-t-body-color">
            Consumers (Token holders, Traders, etc.)
            <br />
            Guaranteed allocation in MercurX Launchpad - Lock Mechanism at beginning
            <br />
            * Can buy tokens on the both centralized and decentralized exchange
            <br />
            * Can buy tokens from the platform directly
            <br />
            * Can pay service fees with token
            <br />
            * Can provide liquidity and earn rewards
            <br />
            * Can earn increased referral rewards when holding tokens
            <br />
            <br />
            Stakers - 2nd Phase(TBD)
            <br />
            * Earn staking rewards
            <br />
            <br />
            Platform
            <br />
            * Can earn service fees
            <br />
            * Can earn tokens from transaction fees
            <br />
            * Can sell tokens to citizens
            <br />* Can burn tokens to decrease supply and increase value
          </div>
        </div>
        {/* <div id="project-about-content-7" className="mt-2">
          <div className="text-fs-head-xxs my-2">TOKENOMICS</div>
          <div className="text-fs-body-md text-t-body-color">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
            has been the industry's standard dummy text ever since the 1500s, when an unknown
            printer took a galley of type and scrambled it to make a type specimen book. It has
            survived not only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s with the release of
            Letraset sheets containing Lorem Ipsum passages, and more recently with desktop
            publishing software like Aldus PageMaker including versions of Lorem Ipsum.
          </div>
        </div>
        <div id="project-about-content-8" className="mt-2">
          <div className="text-fs-head-xxs my-2">TEAM</div>
          <div className="text-fs-body-md text-t-body-color">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
            has been the industry's standard dummy text ever since the 1500s, when an unknown
            printer took a galley of type and scrambled it to make a type specimen book. It has
            survived not only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s with the release of
            Letraset sheets containing Lorem Ipsum passages, and more recently with desktop
            publishing software like Aldus PageMaker including versions of Lorem Ipsum.
          </div>
        </div>
        <div id="project-about-content-9" className="mt-2">
          <div className="text-fs-head-xxs my-2">QUOTE ABOUT WORKING WITH MERCURX</div>
          <div className="text-fs-body-md text-t-body-color">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
            has been the industry's standard dummy text ever since the 1500s, when an unknown
            printer took a galley of type and scrambled it to make a type specimen book. It has
            survived not only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s with the release of
            Letraset sheets containing Lorem Ipsum passages, and more recently with desktop
            publishing software like Aldus PageMaker including versions of Lorem Ipsum.
          </div>
        </div>
        <div id="project-about-content-10" className="mt-2">
          <div className="text-fs-head-xxs my-2">PARTNERS</div>
          <div className="text-fs-body-md text-t-body-color">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
            has been the industry's standard dummy text ever since the 1500s, when an unknown
            printer took a galley of type and scrambled it to make a type specimen book. It has
            survived not only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s with the release of
            Letraset sheets containing Lorem Ipsum passages, and more recently with desktop
            publishing software like Aldus PageMaker including versions of Lorem Ipsum.
          </div>
        </div> */}
      </Col>
    </Row>
  );
}

export default ProjectAboutTheProject;
